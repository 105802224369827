import React, {Component} from 'react';
import Translator         from "../../components/Translator";

class ErrorHandler extends Component
{
    getErrorContent()
    {
        return <div className="error-message">
            <h5>
                <i className="icon icon-stayery-exclude"/>
                {(this.props.customMessage?.title ?? '') !== '' ? (this.props.customMessage?.title ?? '') : <Translator content="messages.errors.errorTitle"/>}
            </h5>
            {(this.props.customMessage?.body ?? '') !== '' ? (this.props.customMessage?.body ?? '') : <Translator content={this.props.errorMessage}/>}
        </div>;
    }

    getWarningContent()
    {
        return <div className="warning-message">
            <i className="icon icon-stayery-exclude"/>
            <Translator content={'messages.timeout'}/>
        </div>;
    }

    render()
    {
        return (this.props.messageType === 'warning') ? this.getWarningContent() : this.getErrorContent();
    }
}

export default ErrorHandler;
