// @flow
import React, {Component} from 'react';
import Header             from "../Header/Header";
import {Redirect}         from "react-router";
import ErrorHandler       from "../ErrorHandler/ErrorHandler";
import LoadingSpinner     from "../LoadingSpinner/LoadingSpinner";
import Translator         from "../../components/Translator";
import translator         from "counterpart";
import * as Sentry        from "@sentry/browser";

class Corona extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            errors:           {},
            errorMessage:     '',
            showErrorMessage: false,
            isSpinnerVisible: false,
            coronaValue:      ""
        };

        this.handleSubmit  = this.handleSubmit.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
    }

    componentDidMount()
    {
        if (!this.props.reservation) {
            return <Redirect to={'/'} refresh={true}/>;
        }
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();
        if (this.formIsValid()) {
            this.setState({isSpinnerVisible: true}, this.gotoSignature);
        }
        return false;
    }

    gotoSignature()
    {
        this.setState({isSpinnerVisible: true}, () => {
            Sentry.addBreadcrumb({message: 'Reservation ' + this.props.reservation.id + ' corona => signature'});
            this.props.history.push({
                                        pathname: 'signature'
                                    }, {
                                        formData: this.props.location.state.formData
                                    });
        })
    }

    formIsValid()
    {
        let isValid = true;
        let errors  = {};

        if (this.state.coronaValue === "") {
            isValid               = false;
            errors['coronaValue'] = translator.translate("messages.errors.required");
            this.showErrorMessage("messages.errors.required");
        }

        this.setState({errors: errors});
        return isValid;
    }

    showErrorMessage(translationId)
    {
        this.setState({
                          errorMessage:     translationId,
                          showErrorMessage: true
                      });
        setTimeout(() => {
            this.setState({showErrorMessage: false})
        }, 8000);
    }

    onValueChange(event)
    {
        let name        = event.target.name;
        let coronaValue = event.target.value;
        this.setState({coronaValue});
    }

    render()
    {

        if (!this.props.reservation) {
            return <Redirect to={'/'} refresh={true}/>;
        }

        let propertyId     = 'DEFAULT';
        let showTestBullet = true;

        return (<div className="page-corona">
            <LoadingSpinner isSpinnerVisible={this.state.isSpinnerVisible}/>
            <div className="page-inner-wrapper">
                {(this.state.showErrorMessage) ? <ErrorHandler errorMessage={this.state.errorMessage}/> : false}

                <Header
                    backButtonVisible={true}
                    headerTitle="pageTitles.coronaPage"
                />
                <div className="content-wrapper">
                    <div className="container">
                        <div className="content-inner-wrapper">
                            <form onSubmit={this.handleSubmit} noValidate={true} autoComplete="off">
                                <div className="row">
                                    <div className="col-4 __left-col">
                                        <div className="bodytext-app">
                                            <Translator content={"corona." + propertyId + ".intro"}/>
                                        </div>
                                    </div>
                                    <div className="col-2 ">

                                    </div>
                                    <div className={"col-6 __right-col"}>
                                        <div className="custom-radio-container-wrap">
                                            <label htmlFor="corona-impfung" className="custom-radio-container">
                                                <Translator className="__label"
                                                            content={"corona." + propertyId + ".impfung"}/>
                                                <input
                                                    type="radio"
                                                    name="coronaValue"
                                                    id="corona-impfung"
                                                    value={"impfung"}
                                                    checked={this.state.coronaValue === "impfung"}
                                                    onChange={this.onValueChange}/>
                                                <span className="__checkmark"/>
                                            </label>
                                        </div>
                                        <div className="custom-radio-container-wrap">
                                            <label htmlFor="corona-genesen" className="custom-radio-container">
                                                <Translator className="__label"
                                                            content={"corona." + propertyId + ".genesen"}/>
                                                <input
                                                    type="radio"
                                                    name="coronaValue"
                                                    id="corona-genesen"
                                                    value={"genesen"}
                                                    checked={this.state.coronaValue === "genesen"}
                                                    onChange={this.onValueChange}/>
                                                <span className="__checkmark"/>
                                            </label>
                                        </div>
                                        {(() => {
                                            if (showTestBullet) {
                                                return <div className="custom-radio-container-wrap">
                                                    <label htmlFor="corona-test" className="custom-radio-container">
                                                        <Translator className="__label"
                                                                    content={"corona." + propertyId + ".test"}/>
                                                        <input
                                                            type="radio"
                                                            name="coronaValue"
                                                            id="corona-test"
                                                            value={"test"}
                                                            checked={this.state.coronaValue === "test"}
                                                            onChange={this.onValueChange}/>
                                                        <span className="__checkmark"/>
                                                    </label>
                                                </div>;
                                            }
                                        })()}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4 __left-col">
                                        <div className="bodytext-app _show-arrow">
                                            <Translator
                                                content={"corona." + propertyId + ".footer"}/>
                                        </div>
                                    </div>
                                    <div className="col-2 ">
                                    </div>
                                    <div className={"col-6 __right-col"}>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className="col-6 __left-col">
                                    </div>
                                    <div className="col-6 __left-col">
                                        <button
                                            id={'buttons.confirm'}
                                            data-role={"buttons.confirm"}
                                            hidden={this.state.coronaValue === ''}
                                            className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
                                            <Translator content="buttons.confirm"/>
                                            <i className="icon icon-stayery-arrow-r"/>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default Corona;
