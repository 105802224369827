import React, {Component} from 'react';
import Translator         from '../../components/Translator';
import packageJson        from '../../../package.json';
import Header             from "../Header/Header";

class Help extends Component
{
    render()
    {

        let hotlineNumber = <Translator content={"help.number"}/>;

        const localStorageHotline = localStorage.getItem('hotline')
        if (localStorageHotline && localStorageHotline !== '') {
            hotlineNumber = <span>{localStorageHotline}</span>
        }

        if (this.props.showHelp) {
            return (<div className="page page-help">
                <div className="page-inner-wrapper">
                    {/* app-header only included for spacing. no interaction allowed. Is needed this way because we need
                      * to refactor application otherwise for state management( to use redux or context
                      */}
                    <Header
                        headerTitle="pageTitles.helppage"
                    />
                    <div className="content-wrapper">
                        <div className="container">
                            <div className="bodytext-app">
                                <Translator content={"help.text"}/>
                            </div>
                            <div className="bodytext-app _hotline">
                                <span className="bigger">
                                    {hotlineNumber}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    position:   'absolute',
                    left:       0,
                    top:        0,
                    opacity:    0.1,
                    userSelect: "none"
                }}>{packageJson.version}</div>
            </div>);
        } else {
            return '';
        }

    }
}

export default Help;
