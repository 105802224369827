// @flow
import React, {Component} from 'react';
import type {SignedGuest} from "@stayery/check-in-terminal-js-client";
import Header             from "../Header/Header";
import {Redirect}         from "react-router";
import ErrorHandler       from "../ErrorHandler/ErrorHandler";
import LoadingSpinner     from "../LoadingSpinner/LoadingSpinner";
import * as Sentry        from '@sentry/browser';
import CountrySelect      from "../../components/CountrySelect";
import counterpart        from "counterpart";
import translator         from "counterpart";
import Translator         from "../../components/Translator";

class Citytax extends Component
{

    cityTaxRequired = {
        leisure:  {
            travelReason: true,
            company:      false,
            address:      false,
            postalCode:   false,
            city:         false,
            country:      false
        },
        business: {
            travelReason: true,
            company:      true,
            address:      true,
            postalCode:   true,
            city:         true,
            country:      true
        }
    };

    constructor(props)
    {
        super(props);

        this.state = {
            errors:                {},
            errorMessage:          '',
            showErrorMessage:      false,
            isSpinnerVisible:      false,
            travelReasonShowState: "",
            citytaxAddress:        { //TODO Type
                travelReason: "",
                company:      "",
                address:      "",
                postalCode:   "",
                city:         "",
                country:      ""
            }
        };

        this.formInputTextWithLabel = this.formInputTextWithLabel.bind(this);
        this.handleChange           = this.handleChange.bind(this);
        this.signatureClear         = this.signatureClear.bind(this);
        this.onTravelReasonChange   = this.onTravelReasonChange.bind(this);
    }

    componentDidMount()
    {
        if (!this.props.reservation) {
            return <Redirect to={'/'} refresh={true}/>;
        }
        let reservation = this.props.reservation;
        let address     = reservation._meta['citytaxAddress'];

        this.setState({
                          citytaxAddress: {
                              ...this.state.citytaxAddress,
                              travelReason: "",
                              company:      address.company || "",
                              address:      address.address || "",
                              postalCode:   address.postalCode || "",
                              city:         address.city || "",
                              country:      address.country || "DE"
                          }
                      });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.formIsValid()) {
            this.setState({isSpinnerVisible: true});
            this.checkInNow();
        }
        return false;
    }

    checkInNow()
    {
        this.setState({isSpinnerVisible: true});
        let signedGuest: SignedGuest = this.props.location.state.formData;
        signedGuest.citytax          = this.state.citytaxAddress;

        this.props.apiClient.checkIn(this.props.reservation.id, signedGuest).then((response) => {
            this.setState({isSpinnerVisible: false});

            if (response.error) {
                let errorMsg = "address.errors.msg1";
                if (typeof response.reason !== "undefined") {
                    switch (response.reason) {
                        case'occupied':
                            errorMsg = "address.errors.occupied";
                            break;
                        case'future':
                        case "past":
                            errorMsg = "address.errors.outOfTime";
                            break;
                        case'unknown':
                        default:
                            errorMsg = "address.errors.msg1";
                            break;
                    }
                }
                this.showErrorMessage(errorMsg);
                return false;
            } else {
                if (response.checkin === 'success') {
                    Sentry.addBreadcrumb({
                                             message: 'Reservation ' + (this.props.reservation?.id ?? '-') +
                                                          ' citytax => roominformation'
                                         });
                    this.props.history.push({
                                                pathname: 'roominformation'
                                            }, {
                                                roomname: response.roomname
                                            });
                } else {
                    Sentry.addBreadcrumb({
                                             message: 'Reservation ' + (this.props.reservation?.id ?? '-') +
                                                          ' citytax => consultfrontoffice'
                                         });
                    this.props.history.push({
                                                pathname: 'consultfrontoffice'
                                            });
                }
            }

        }).catch((err) => {
            // FIXME: unhandled Exception
            Sentry.captureException(err);
            this.setState({isSpinnerVisible: false});
        });
    }

    handleChange(event)
    {
        const errors = this.state.errors;
        if (errors.hasOwnProperty(event.target.name)) {
            delete errors[event.target.name];
        }
        this.setState({
                          errors:         errors,
                          citytaxAddress: {
                              ...this.state.citytaxAddress,
                              [event.target.name]: event.target.value
                          }
                      });
    }

    signatureClear(event)
    {
        event.preventDefault();
        if (this.signaturePad) {
            this.signaturePad.clear();
        }
    }

    formInputTextWithLabel(
        label: string,
        name: string,
        values: string,
        type: string,
        readOnly: boolean,
        required: boolean,
        maxlength: number | null
    )
    {
        let value             = values;
        let inputType         = type || 'text';
        let requiredClass     = "control-label " + (required ? 'required' : 'optional');
        let className         = "form-control";
        let classNameReadOnly = readOnly ? 'readonly disabled' : '';
        let errorMessage      = null;

        let reqSpan = '';

        if (this.state.errors.hasOwnProperty(name)) {
            className += " is-invalid";
        }

        if (typeof maxlength === 'undefined') {
            maxlength = null;
        }

        return (<div className="form-group">
            <label className={requiredClass}>
                <Translator content={label}/>
                {reqSpan}
            </label>
            <div className={classNameReadOnly}>
                <input type={inputType}
                       className={className}
                       value={value}
                       name={name}
                       title={errorMessage}
                       required={required}
                       readOnly={readOnly}
                       disabled={readOnly}
                       onChange={this.handleChange}
                       autoComplete="off"
                       placeholder=" "
                       maxLength={maxlength}
                />
                <i className="icon icon-stayery-group hidden"/>
            </div>
        </div>);
    }

    formIsValid()
    {
        let isValid        = true;
        let cityTaxForm    = this.state.citytaxAddress;
        let errors         = {};
        let requiredFields = this.cityTaxRequired.leisure;

        if (this.state.citytaxAddress.travelReason === "business") {
            requiredFields = this.cityTaxRequired.business;
        }

        for (let field in requiredFields) {
            if (requiredFields.hasOwnProperty(field)) {
                let required = requiredFields[field];
                if (required && !(cityTaxForm.hasOwnProperty(field) && cityTaxForm[field])) {
                    isValid       = false;
                    errors[field] = translator.translate("messages.errors.required");
                    this.showErrorMessage("messages.errors.required");
                }
            }
        }

        this.setState({errors: errors});
        return isValid;
    }

    isFieldRequired(field)
    {
        let required = false;
        if (this.cityTaxRequired.hasOwnProperty(field)) {
            required = this.cityTaxRequired[field];
        }

        return required;
    }

    showErrorMessage(translationId)
    {
        this.setState({
                          errorMessage:     translationId,
                          showErrorMessage: true
                      });
        setTimeout(() => {
            this.setState({showErrorMessage: false})
        }, 8000);
    }

    onTravelReasonChange(event)
    {
        let name  = event.target.name;
        let value = event.target.value;
        this.setState({
                          citytaxAddress: {
                              ...this.state.citytaxAddress,
                              [name]: value
                          }
                      }, function() {
            if (this.state.travelReasonShowState === "") {
                setTimeout(() => {
                    this.setState({travelReasonShowState: value});
                }, 1000);
            } else {
                this.setState({travelReasonShowState: value});
            }
        });
    }

    getCityTaxModeSafe()
    {
        return localStorage.getItem('citytaxMode') ?? 'default';
    }

    render()
    {
        if (!this.props.reservation) {
            return <Redirect to={'/'} refresh={true}/>;
        }

        const reservation       = this.props.reservation;
        const travelReasonClass = this.state.travelReasonShowState !== "" ? " _" + this.state.travelReasonShowState
                                                                          : "";

        return (<div className="page-citytax">
            <LoadingSpinner isSpinnerVisible={this.state.isSpinnerVisible}/>
            <div className="page-inner-wrapper">
                {(this.state.showErrorMessage) ? <ErrorHandler errorMessage={this.state.errorMessage}/> : false}

                <Header
                    backButtonVisible={true}
                    headerTitle="pageTitles.citytaxPage"
                />
                <div className="content-wrapper">
                    <div className="container">
                        <div className="content-inner-wrapper">
                            <form onSubmit={this.handleSubmit} noValidate={true} autoComplete="off">
                                <div className="row">
                                    <div className="col-4 __left-col">
                                        <div className="bodytext-app">
                                            {(() => {
                                                switch (this.state.travelReasonShowState) {
                                                    case 'leisure':
                                                        return <Translator
                                                            content="citytax.text.headline_leisure"/>;
                                                    case 'business':
                                                        return <Translator
                                                            content="citytax.text.headline_business"/>;
                                                    case 'done_business':
                                                    case 'done_leisure':
                                                        return this.renderFirstBulletpoint(reservation);
                                                    default:
                                                        return <Translator
                                                            content="citytax.text.headline"/>;
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    <div className="col-2 ">
                                    </div>
                                    <div className={"col-6 __right-col" + travelReasonClass +
                                        (" _" + this.getCityTaxModeSafe() + " ") +
                                        (reservation._meta.hasCityTax ? " _has-citytax" : "")}>
                                        {(this.state.travelReasonShowState === 'done_leisure' ||
                                            this.state.travelReasonShowState === 'done_business') ? null : (
                                             <div style={{width: "100%"}}>
                                                 <div className="custom-radio-container-wrap">
                                                     <label htmlFor="travelReason-leisure"
                                                            className="custom-radio-container">
                                                         <Translator className="__label" content="citytax.leisure"/>
                                                         <input
                                                             type="radio"
                                                             name="travelReason"
                                                             id="travelReason-leisure"
                                                             value={"leisure"}
                                                             checked={this.state.citytaxAddress.travelReason ===
                                                                 "leisure"}
                                                             onChange={this.onTravelReasonChange}/>
                                                         <span className="__checkmark"/>
                                                     </label>
                                                     <label htmlFor="travelReason-business"
                                                            className="custom-radio-container">
                                                         <Translator className="__label" content="citytax.business"/>
                                                         <input
                                                             type="radio"
                                                             name="travelReason"
                                                             id="travelReason-business"
                                                             value={"business"}
                                                             checked={this.state.citytaxAddress.travelReason ===
                                                                 "business"}
                                                             onChange={this.onTravelReasonChange}/>
                                                         <span className="__checkmark"/>
                                                     </label>

                                                 </div>
                                             </div>)}
                                        {(() => {
                                            switch (this.state.travelReasonShowState) {
                                                case 'business':
                                                    return this.renderBusinessForm();
                                                case 'leisure':
                                                    return this.renderLeisureStep(reservation);
                                                case 'done_leisure':
                                                case 'done_business':
                                                    return this.renderBulletPointsStep(reservation);
                                                default:
                                                    return null;
                                            }
                                        })()}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    renderLeisureStep = (reservation) => {
        return <div style={{width: "100%"}}>
            {this.getCityTaxModeSafe() === 'default' ? this.renderCityTaxBulletPoints(reservation) : null}
            {this.getCityTaxModeSafe() === 'always' ? this.renderNextButton((event) => {
                event.preventDefault();
                event.stopPropagation();
                this.setState({travelReasonShowState: 'done_leisure'});
            }) : this.renderSubmitButton()}
        </div>;
    }

    renderBulletPointsStep = (reservation) => {
        return <div style={{width: "100%"}}>
            {this.renderCityTaxBulletPoints(reservation)}
            {this.renderSubmitButton()}
        </div>;
    }

    renderCityTaxBulletPoints = (reservation) => {
        let id = reservation.property.id;

        return <div>
            {this.getCityTaxModeSafe() !== 'always' ? (this.renderFirstBulletpoint(reservation)) : null}

            <div className="bodytext-app">
                <Translator
                    content={"citytax.textLeisure." + id + ".2"}
                    fallback={counterpart.translate("citytax.textLeisure.2")}
                />

            </div>
            <div className="bodytext-app">
                <Translator
                    content="citytax.textLeisure.3"/>
            </div>
        </div>;
    }

    renderFirstBulletpoint = (reservation) => {
        let id = reservation.property.id;

        return (<div className="bodytext-app">
            <Translator
                content={"citytax.textLeisure." + id + ".1"}
                fallback={counterpart.translate("citytax.textLeisure.1")}/>
        </div>)
    }

    renderSubmitButton = () => {
        return <button className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
            <Translator
                content="citytax.confirmPayment"/>
            <i className="icon icon-stayery-arrow-r"/>
        </button>;
    }

    renderNextButton = (onClick) => {
        return <button
            className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space"
            type="button"
            onClick={onClick}
        >
            <Translator content="citytax.nextButton"/>
            <i className="icon icon-stayery-arrow-r"/>
        </button>;
    }

    renderBusinessForm = () => {
        const onClick = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (this.formIsValid()) {
                this.setState({travelReasonShowState: 'done_business'});
            }
        };

        return <div style={{width: "100%"}}>
            <div className="form-group">
                {this.formInputTextWithLabel("fields.company",
                                             'company',
                                             this.state.citytaxAddress.company,
                                             'text',
                                             false,
                                             true
                )}
            </div>
            <div className="form-group">
                {this.formInputTextWithLabel("fields.address",
                                             'address',
                                             this.state.citytaxAddress.address,
                                             'text',
                                             false,
                                             true
                )}
            </div>
            <div className="form-group">
                {this.formInputTextWithLabel("fields.postalCode",
                                             'postalCode',
                                             this.state.citytaxAddress.postalCode,
                                             'text',
                                             false,
                                             true,
                                             20
                )}
            </div>
            <div className="form-group">
                {this.formInputTextWithLabel("fields.city",
                                             'city',
                                             this.state.citytaxAddress.city,
                                             'text',
                                             false,
                                             true
                )}
            </div>
            <div className="form-group">
                <CountrySelect
                    label="fields.country"
                    required={true}
                    name="country"
                    value={this.state.citytaxAddress.country}
                    onChange={this.handleChange}
                    className={this.state.errors.hasOwnProperty("country") ? "is-invalid" : ""}
                />
            </div>
            {this.getCityTaxModeSafe() === 'always' ? this.renderNextButton(onClick) : this.renderSubmitButton()}
        </div>;
    }

    getFolioAddress(debitor, bookingChannel)
    {

        let address = {
            company:    "",
            address:    "",
            postalCode: "",
            city:       "",
            country:    ""
        };

        if (bookingChannel === 'Direct') {
            if (debitor.address) {
                address.address    = debitor.address.addressLine1;
                address.postalCode = debitor.address.postalCode;
                address.city       = debitor.address.city;
                address.country    = debitor.address.countryCode;
            }
            if (debitor.company) {
                address.company = debitor.company.name;
            }
        } else {
            if (debitor.company) {
                address.company = debitor.company.name;
            }
        }

        return address;
    }
}

export default Citytax;
