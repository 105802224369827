import React, {Component} from "react";
import Header             from "../Header/Header";
import Translator         from "../../components/Translator";

class Checkout extends Component
{
    render()
    {
        const uuid = this.props.history?.location?.state?.uuid ?? 'NaN';
        let hotelId = localStorage.getItem('hotelId');

        if (hotelId === null || hotelId === undefined) {
            hotelId = "default";
        }

        const btnOutText  = `checkout.${hotelId}.buttons`;
        const contentText = `checkout.${hotelId}.text`;

        return (<div className="page-checkout">
            <div className="page-inner-wrapper">
                <Header
                    backButtonVisible={true}
                    headerTitle="pageTitles.checkoutPage"
                    onBack={() => {
                        try {
                            this.props.apiClient.postAnalyticsData(uuid, 'go back');
                        } catch (e) {
                            console.error(e);
                        }
                    }}

                />
                <div className="content-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="bodytext-app">
                                    <Translator content={contentText}/>
                                </div>
                            </div>
                            <div className="col-6">
                                <img
                                    src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExa2NzMzJyMWdjb2xtc2h5d2VrNHBiMHdlNTBmaWkzdnExNjMwZm0wYyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/kaBU6pgv0OsPHz2yxy/giphy.gif"
                                    width="100%"
                                    alt="Goodbye"
                                />
                                <button
                                    onClick={() => {
                                        try {
                                            this.props.apiClient.postAnalyticsData(uuid, 'go to /main/thankyou');
                                        } catch (e) {
                                            console.error(e);
                                        }
                                        this.props.history.push({pathname: '/main/thankyou'})
                                    }}
                                    className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
                                    <Translator content={btnOutText}/>
                                    <i className="icon icon-stayery-arrow-r"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default Checkout;
