// @flow
import React, {Component} from 'react';
import Translator         from "../../components/Translator";
import Header             from "../Header/Header";
import ErrorHandler       from "../ErrorHandler/ErrorHandler";
import LoadingSpinner     from "../LoadingSpinner/LoadingSpinner";
import * as Sentry        from '@sentry/browser';
import translator         from "counterpart";
import {DatePicker}       from "../../components/datepicker/DatePicker";

class LostCard extends Component
{

    formLoginRequired = {
        roomnumber:  true,
        lastName:    true,
        birthDate:   true,
        arrivalDate: true
    };

    constructor(props)
    {
        super(props);

        this.state = {
            errors:           {},
            errorMessage:     '',
            showErrorMessage: false,
            isSpinnerVisible: false,
            verification:     {
                roomnumber:  process.env.REACT_APP_ROOMNUMBER ?? '',
                lastName:    process.env.REACT_APP_LASTNAME ?? '',
                birthDate:   process.env.REACT_APP_BIRTH_DATE ?? '',
                arrivalDate: process.env.REACT_APP_CHECKOUT ?? ''
            }
        };

        this.submitVerification = this.submitVerification.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
        this.handleChange       = this.handleChange.bind(this);
    }

    submitVerification()
    {
        let verification = this.state.verification;
        this.setState({isSpinnerVisible: true});

        this.props.apiClient.lostCardVerification(verification.roomnumber,
                                                  verification.lastName,
                                                  verification.birthDate,
                                                  verification.arrivalDate
        ).then(response => {

            if (typeof response === 'undefined' || response === null) {
                response = {
                    error: 'notFound'
                }
                Sentry.captureEvent({
                                        message: 'Response was null or undefined'
                                    });
            }

            this.setState({isSpinnerVisible: false});
            if (response.error) {
                this.showErrorMessage('lostcard.errors.notFound');
                return false;
            } else {
                this.createCard(response);
            }
        }).catch((err) => {
            // FIXME: unhandled Exception
            Sentry.captureException(err);
            this.setState({isSpinnerVisible: false});
        });
    }

    // TODO: Umlagern -> serverseitig
    createCard(reservation)
    {
        this.setState({isSpinnerVisible: true});
        const twoCardsAllowed = reservation?._meta?.twoCardsAllowed ?? false;

        const cardNum  = twoCardsAllowed ? 2 : 1;
        const checkOut = reservation.departure;
        const checkIn  = reservation.arrival;
        const roomNr   = String(reservation.unit.name);
        this.props.apiClient.requestKeyCard(roomNr, checkIn, checkOut, cardNum)
            .then(response => {
                if (typeof response === 'undefined' || response === null) {
                    response = {
                        error: 'createcard'
                    }
                    Sentry.captureEvent({
                                            message: 'Response was null or undefined'
                                        });
                }
                this.setState({isSpinnerVisible: false});

                if (response.error) {
                    let errorMsg = 'roominformation.errors.createcard';
                    if (typeof response.reason !== 'undefined') {
                        switch (response.reason) {
                            case 4096:
                            case 4098:
                            case 4099:
                            case 4100:
                            case 4101:
                            case 4103:
                            case 8194:
                                errorMsg = `roominformation.errors.${response.reason}`;
                                break;
                            default:
                                errorMsg = 'roominformation.errors.createcard';
                                break;
                        }
                    }
                    this.showErrorMessage(errorMsg);

                    return false;
                } else {
                    this.props.history.push({
                                                pathname: 'keycard'
                                            }, {
                                                roomNumber: reservation.unit.name,
                                                cardNum:    cardNum
                                            });
                }
            })
            .catch((err) => {
                // FIXME: unhandled Exception
                Sentry.captureException(err);
                this.setState({isSpinnerVisible: false});
            });
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();

        if (this.formIsValid()) {
            this.submitVerification()
        }
        return false;
    }

    handleChange(event)
    {
        let verification                = this.state.verification;
        verification[event.target.name] = event.target.value;

        const errors = this.state.errors;
        if (errors.hasOwnProperty(event.target.name)) {
            delete errors[event.target.name];
        }
        this.setState({
                          errors:       errors,
                          verification: verification
                      });
    }

    formInputTextWithLabel(label: string, name: string, value: string, type: string)
    {
        let className = "form-control";

        if (this.state.errors.hasOwnProperty(name)) {
            className += " is-invalid";
        }

        return (<div className="form-group">
            <label className="control-label" style={{whiteSpace: "nowrap"}}>
                <Translator content={label}/>
            </label>
            <div>
                <input type={type}
                       className={className}
                       value={value}
                       name={name}
                       onChange={this.handleChange}
                       autoComplete="off"
                       placeholder=" "/>
                <i className="icon icon-stayery-group hidden"/>
            </div>
        </div>);
    }

    formIsValid()
    {
        let isValid      = true;
        let verification = this.state.verification;
        let errors       = {};
        for (let field in this.formLoginRequired) {
            if (this.formLoginRequired.hasOwnProperty(field)) {
                let required = this.formLoginRequired[field];
                if (required && !(verification.hasOwnProperty(field) && verification[field])) {
                    isValid       = false;
                    errors[field] = translator.translate("messages.errors.required");
                    this.showErrorMessage("messages.errors.required");
                }
            }
        }
        this.setState({errors: errors});

        return isValid;
    }

    showErrorMessage(translationId)
    {
        this.setState({
                          errorMessage:     translationId,
                          showErrorMessage: true
                      });
        setTimeout(() => {
            this.setState({showErrorMessage: false})
        }, 8000);
    }

    render()
    {
        return (<div className="page-lostcard">
            <LoadingSpinner
                isSpinnerVisible={this.state.isSpinnerVisible}
            />
            <div className="page-inner-wrapper">
                {(this.state.showErrorMessage) ? <ErrorHandler
                    errorMessage={this.state.errorMessage}
                /> : false}
                <Header
                    backButtonVisible={true}
                    headerTitle="pageTitles.lostcardPage"
                />
                <div className="content-wrapper">
                    <div className="container">
                        <div className="content-inner-wrapper">
                            <div className="row">
                                <div className="col-6">
                                    <div className="bodytext-app"><Translator
                                        content={"lostcard.text.description"}/></div>
                                </div>
                                <div className="col-6">
                                    <form className="stayery-form" onSubmit={this.handleSubmit} autoComplete="off">
                                        <div className="row">
                                            <div className="col-3">
                                                {this.formInputTextWithLabel("fields.roomnumber",
                                                                             'roomnumber',
                                                                             this.state.verification.roomnumber,
                                                                             'text'
                                                )}
                                            </div>
                                            <div className="col-9">
                                                {this.formInputTextWithLabel("fields.lastName",
                                                                             'lastName',
                                                                             this.state.verification.lastName,
                                                                             'text'
                                                )}
                                            </div>
                                            <div className="col-12">
                                                <DatePicker
                                                    label={'fields.arrivalDate'}
                                                    value={this.state.verification.birthDate}
                                                    name="arrivalDate"
                                                    onChange={this.handleChange}
                                                    error={this.state.errors.hasOwnProperty("arrivalDate")}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <DatePicker
                                                    label={'fields.birthDate'}
                                                    value={this.state.verification.birthDate}
                                                    name="birthDate"
                                                    onChange={this.handleChange}
                                                    error={this.state.errors.hasOwnProperty("birthDate")}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
                                            <Translator content={"lostcard.buttons.submit"}/>
                                            <i className="icon icon-stayery-arrow-r"/>
                                        </button>
                                        <div className="bodytext-app">
                                            <strong><Translator content={"lostcard.text.ctaNote"}/>&nbsp;</strong>
                                            <Translator content={"lostcard.text.cta"}/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default LostCard;
