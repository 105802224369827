import React, {Component} from 'react';
import Header             from "../Header/Header";
import Translator         from "../../components/Translator";
import {Redirect}         from "react-router";
import * as Sentry        from "@sentry/browser";
import {Severity}         from "@sentry/types/dist/severity";

class Keycard extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            redirect: false
        };

        setTimeout(() => {
            this.setState({redirect: true})
        }, 80000);
        this.handleOK = this.handleOK.bind(this);
    }

    handleOK(event)
    {
        this.setState({redirect: true})
    }

    componentDidMount = () => {
        Sentry.captureEvent({
                                level:   Severity.Info,
                                message: 'Reservation ' + (this.props.reservation?.id ?? '-') +
                                             ' Checkin completed: keycard'
                            });
    }

    render()
    {
        if (this.state.redirect) {
            return (<Redirect refresh={true} to="/main/thankyou"/>);
        }

        let roomNumber = '###';
        if (this.props.location.state.roomNumber) {
            roomNumber = this.props.location.state.roomNumber;
        }

        let cardNum = 1;
        if (this.props.location.state.cardNum) {
            cardNum = this.props.location.state.cardNum;
        }

        let hotlineNumber = <Translator content={"help.number"}/>;

        const localStorageHotline = localStorage.getItem('hotline')
        if (localStorageHotline && localStorageHotline !== '') {
            hotlineNumber = <span>{localStorageHotline}</span>
        }
        return (<div className="page-keycard">
            <div className="page-inner-wrapper">
                <Header
                    headerTitle="pageTitles.keycardPage"
                />
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-6">
                            <div className="bodytext-app __number-wrap">
                                <strong className="__number">{roomNumber}</strong>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="bodytext-app">
                                <h2 className="h2-heading __title">
                                    {cardNum === 1 ? (<Translator content="keycard.title"/>) : (
                                        <Translator content="keycard.title_2"/>)}
                                </h2>
                                <div className="__description">
                                    {cardNum === 1 ? (<Translator content={"keycard.checkinDescription"}/>) : (
                                        <Translator content={"keycard.checkinDescription_2"}/>)}
                                </div>

                                <h2 className="h2-heading __hotline">
                                    {hotlineNumber}
                                </h2>
                                <button onClick={this.handleOK}
                                        className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
                                    <Translator content={"keycard.button"}/> <i
                                    className="icon icon-stayery-arrow-r"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default Keycard;
