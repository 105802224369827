import React, {useState, FC} from "react";
import PropTypes             from 'prop-types';
import translator            from "counterpart";
import Translator            from "./Translator";
import Select, {components}  from 'react-select'
import i18next               from "i18next";

/* @formatter:off */
const countries = ["AL", "DZ", "AS", "AD", "AO", "AI", "AQ", "AG", "AR", "AM", "AW", "AU", "AT", "AZ", "BH", "BD", "BB", "BY", "BE", "BZ", "BJ", "BM", "BT", "BO", "BQ", "BA", "BW", "BV", "BR", "IO", "VG", "BN", "BG", "BF", "BI", "KH", "CM", "CA", "CV", "KY", "CF", "TD", "CL", "CN", "CX", "CC", "CO", "KM", "CD", "CG", "CK", "CR", "HR", "CU", "CW", "CY", "CZ", "CI", "DK", "DJ", "DM", "DO", "EC", "EG", "SV", "GQ", "ER", "EE", "ET", "FK", "FO", "FJ", "FI", "FR", "GF", "PF", "TF", "GA", "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GD", "GP", "GU", "GT", "GG", "GN", "GW", "GY", "HT", "HM", "HN", "HK", "HU", "IS", "IN", "ID", "IR", "IQ", "IE", "IM", "IL", "IT", "JM", "JP", "JE", "JO", "KZ", "KE", "KI", "KW", "KG", "LA", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MO", "MK", "MG", "MW", "MY", "MV", "ML", "MT", "MH", "MQ", "MR", "MU", "YT", "MX", "FM", "MD", "MC", "MN", "ME", "MS", "MA", "MZ", "MM", "NA", "NR", "NP", "NL", "NC", "NZ", "NI", "NE", "NG", "NU", "NF", "KP", "MP", "NO", "OM", "PK", "PW", "PS", "PA", "PG", "PY", "PE", "PH", "PN", "PL", "PT", "PR", "QA", "RE", "RO", "RU", "RW", "BL", "SH", "KN", "LC", "MF", "PM", "VC", "WS", "SM", "SA", "SN", "RS", "SC", "SL", "SG", "SX", "SK", "SI", "SB", "SO", "ZA", "GS", "KR", "SS", "ES", "LK", "SD", "SR", "SJ", "SZ", "SE", "CH", "SY", "ST", "TW", "TJ", "TZ", "TH", "BS", "TL", "TG", "TK", "TO", "TT", "TN", "TR", "TM", "TC", "TV", "VI", "UG", "UA", "AE", "GB", "US", "UM", "UY", "UZ", "VU", "VA", "VE", "VN", "WF", "EH", "YE", "ZM", "ZW", "AX"];
/* @formatter:on */

const createCountryOptions = () => {
    let sortedCountries = [];
    for (let i = 0; i < countries.length; i++) {
        sortedCountries.push({
                                 value: countries[i],
                                 label: translator.translate('countries.' + countries[i])
                             });
    }

    sortedCountries.sort(function(a, b) {
        const left  = cleanString(a.label);
        const right = cleanString(b.label);

        return ((left < right) ? -1 : ((a.label > right) ? 1 : 0));
    });

    //MOVE DE to first position
    for (let index = 0; index < sortedCountries.length; index++) {
        let sortedCountry = sortedCountries[index];
        if (sortedCountry.value === "DE") {
            sortedCountries.unshift(sortedCountry);
            // we change the collection, add one element to the front so the index has to be incremented by one
            delete sortedCountries[index + 1];
            index = sortedCountries.length;
            break;
        }
    }

    sortedCountries.unshift({
                                value: '',
                                label: '  '
                            });

    return sortedCountries;
}

export const cleanString = ($string) => {
    let clone = String($string);

    /* @formatter:off */
    const umlautMap = {
        '\u00dc': 'UE', //Ü
        '\u00c4': 'AE', //Ä
        '\u00C5': 'AE', //Å
        '\u00d6': 'OE', //Ö
        '\u00fc': 'ue', //ü
        '\u00e4': 'ae', //ä
        '\u00E5': 'ae', //å
        '\u00f6': 'oe', //ö
        '\u00df': 'ss', //ß
    }
    /* @formatter:on */

    Object.keys(umlautMap).forEach((searchValue) => {
        const newValue = umlautMap[searchValue];
        clone          = clone.replace(searchValue, newValue);
    })

    return clone;
}

const CountrySelect: FC = (props) => {
    const [focused, setFocussed]                  = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);


    const [options, setOptions] = useState(createCountryOptions())
    const callback              = (lng) => {
        setOptions(createCountryOptions());
    };
    i18next.on('languageChanged', callback);

    const getOptionByValue = (value) => {
        return options.find(option => option && option.value === value);
    }

    const handleChange = (selected) => {
        props.onChange({
                           target: {
                               name:  props.name,
                               value: selected.value
                           }
                       });
    }

    let className = "";
    if (focused) {
        className += "";
    }

    let reqSpan = '';

    let helpBlock = '';
    if (showErrorMessage && props.errormessage) {
        helpBlock = <div className="help-block">
            <div>{props.errormessage}</div>
        </div>;
    }

    const DropdownIndicator = props => {
        return (components.DropdownIndicator && (<components.DropdownIndicator {...props}>
            <i className="icon icon-stayery-arrow-dropdown" />
        </components.DropdownIndicator>));
    };

    const customStyle = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform:  state.selectProps.menuIsOpen ? "rotate(180deg)" : null
        }),
        option:            (styles, {
            data,
            isDisabled,
            isFocused,
            isSelected
        }) => {
            //stayery-pink:
            const color = "#F0B4C8"
            return {
                ...styles,
                backgroundColor: isDisabled ? undefined : isSelected ? color : isFocused ? color : undefined,
                color:           isDisabled ? '#FFBA00' : isSelected ? 'black' : 'black',
                border:          !isDisabled ? isSelected ? '1px solid black' : 'none' : undefined,
                ':active':       {
                    ...styles[':active'],
                    border:          !isDisabled ? isSelected ? 'none' : '1px solid black' : undefined,
                    backgroundColor: !isDisabled ? isSelected ? '' : color : undefined
                }
            };
        }
    };

    return (<div className={className}>
        <label
            htmlFor={props.name}
            className="control-label">
            <Translator content={props.label} />
            {reqSpan}
        </label>
        <Select
            required={props.required}
            className={"form-control _select" + props.className}
            classNamePrefix={'react-select'}
            id={props.name}
            name={props.name}
            value={getOptionByValue(props.value)}
            onChange={handleChange}
            disabled={props.readOnly}
            options={options}
            isSearchable
            components={{
                DropdownIndicator,
                IndicatorSeparator: () => null
            }}
            styles={customStyle}
        />
        {helpBlock}
    </div>);
}

// Definition of LabeledInput component properties
CountrySelect.propTypes = {
    className:    PropTypes.string, // Optional css class name string
    errormessage: PropTypes.string, // Optional error message string
    // formValidation: PropTypes.func, // Optional form validation function
    label:          PropTypes.string.isRequired, // Required input label
    name:           PropTypes.string.isRequired, // Required name (as identifier)
    onChange:       PropTypes.func, // Optional on value change function (to update global state)
    readOnly:       PropTypes.bool, // Optional read only modifier
    spawnWithError: PropTypes.bool, // Optional error display on first render (spawn)
    type:           PropTypes.string, // Optional type of the input
    value:          PropTypes.string // Optional default input value
};

// Definition of default LabeledInput component properties
CountrySelect.defaultProps = {
    className:    "",
    errormessage: "There seems to be an error in this input.", // formValidation: (errors, fieldName) => {
    // },
    onChange:  (event) => {
    },
    readOnly:  false,
    minlength: 0, // spawnWithError: false,
    type:      "",
    label:     "",
    value:     ""
};

export default CountrySelect;
