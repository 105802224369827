// @flow
import React, {Component}                                from 'react';
import Translator                                        from "../../components/Translator";
import Header                                            from "../Header/Header";
import ErrorHandler                                      from "../ErrorHandler/ErrorHandler";
import LoadingSpinner                                    from "../LoadingSpinner/LoadingSpinner";
import * as Sentry                                       from '@sentry/browser';
import translator                                        from "counterpart";
import counterpart                                       from "counterpart";
import {differenceInCalendarDays, parseISO as dateParse} from 'date-fns';

class Login extends Component
{

    formLoginRequired = {
        bookingId: true,
        lastName:  true
    };

    constructor(props)
    {
        super(props);

        this.state = {
            errors:           {},
            errorMessage:     '',
            customMessage:    {
                title: '',
                body:  ''
            },
            showErrorMessage: false,
            isSpinnerVisible: false,
            booking:          {
                bookingId: process.env.REACT_APP_RESERVATION ?? '',
                lastName:  process.env.REACT_APP_LASTNAME ?? ''
            }
        };

        this.submitReservation = this.submitReservation.bind(this);
        this.handleSubmit      = this.handleSubmit.bind(this);
        this.handleChange      = this.handleChange.bind(this);
    }

    submitReservation()
    {
        let booking = this.state.booking;
        this.setState({isSpinnerVisible: true});

        Sentry.addBreadcrumb({message: 'Try login' + booking.bookingId + " / " + booking.lastName});

        this.props.apiClient.getReservation(booking.bookingId, booking.lastName).then((response) => {
            this.setState({isSpinnerVisible: false});

            if (typeof response === 'undefined' || response === null) {
                response = {
                    status: 'notFound'
                }
                Sentry.captureEvent({
                                        message: 'Login-Response was null or undefined'
                                    });
            }

            if (response.status === 'Confirmed') {
                if (this.checkTime(response)) {
                    Sentry.addBreadcrumb({message: 'Login reservation ' + response.id + ' login => address'})
                    this.props.setReservation(response);
                    this.props.history.push({
                                                pathname: 'address'
                                            }, {
                                                primaryGuest: response.primaryGuest
                                            });
                } else {
                    this.showErrorMessage("address.errors.farFuture");
                }

            } else if (response.status === 'InHouse') {
                // Bereits eingecheckt im Hotel. Hinweisen auf Lostcard
                this.showErrorMessage("address.errors.inHouse");
            } else if (response.status === 'outOfTime') {
                this.showErrorMessage("address.errors.outOfTime");
            } else if (response.status === 'notFound') {
                this.showErrorMessage("address.errors.notFound");
            } else if (response.status === 'wrongHotel') {
                const lang                    = counterpart.getLocale();
                const  reservationPropertyName = response.reservationPropertyName[lang] ?? '???';
                const  terminalPropertyName    = response.terminalPropertyName[lang] ?? '???';

                this.showCustomMessage(<Translator
                    content={`address.errors.wrongHotel.default.title`}
                    with={{
                        reservationPropertyName,
                        terminalPropertyName
                    }}
                />, <Translator
                    content={`address.errors.wrongHotel.default.body`}
                    with={{
                        reservationPropertyName,
                        terminalPropertyName
                    }}
                />);
            }
        }).catch((err) => {
            Sentry.captureException(err);
            this.setState({isSpinnerVisible: false});
        });
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();

        if (this.formIsValid()) {
            this.submitReservation()
        }
        return false;
    }

    handleChange(event)
    {
        let booking                = this.state.booking;
        booking[event.target.name] = event.target.value;
        const errors               = this.state.errors;
        if (errors.hasOwnProperty(event.target.name)) {
            delete errors[event.target.name];
        }
        this.setState({
                          errors:  errors,
                          booking: booking
                      });
    }

    formInputTextWithLabel(label: string, name: string, value: string, type: string)
    {
        let inputType = type || 'text';
        let className = "form-control _uppercase";

        if (this.state.errors.hasOwnProperty(name)) {
            className += " is-invalid";
        }

        return (<div className="form-group">
            <label className="control-label">
                <Translator content={label} />
            </label>
            <div>
                <input type={inputType}
                       className={className}
                       value={value}
                       name={name}
                       onChange={this.handleChange}
                       autoComplete="off"
                       placeholder=" " />
                <i className="icon icon-stayery-group hidden" />
            </div>
        </div>);
    }

    formIsValid()
    {
        let isValid = true;
        let booking = this.state.booking;
        let errors  = {};
        for (let field in this.formLoginRequired) {
            if (this.formLoginRequired.hasOwnProperty(field)) {
                let required = this.formLoginRequired[field];
                if (required && !(booking.hasOwnProperty(field) && booking[field])) {
                    isValid       = false;
                    errors[field] = translator.translate("messages.errors.required");
                    this.showErrorMessage("messages.errors.required");
                }
            }
        }
        this.setState({errors: errors});

        return isValid;
    }

    showErrorMessage(translationId)
    {
        this.setState({
                          errorMessage:     translationId,
                          showErrorMessage: true
                      });
        setTimeout(() => {
            this.setState({showErrorMessage: false})
        }, 8000);
    }

    showCustomMessage(title, body)
    {
        this.setState({
                          showErrorMessage: true,
                          customMessage:    {
                              title: title,
                              body:  body
                          }
                      });
        setTimeout(() => {
            this.setState({
                              showErrorMessage: false,
                              customMessage:    {
                                  title: '',
                                  body:  ''
                              }
                          })
        }, 8000);
    }

    render()
    {
        return (<div className="page-login">
            <LoadingSpinner
                isSpinnerVisible={this.state.isSpinnerVisible}
            />
            <div className="page-inner-wrapper">
                {(this.state.showErrorMessage) ? <ErrorHandler errorMessage={this.state.errorMessage}
                                                               customMessage={this.state.customMessage} /> : false}
                <Header
                    backButtonVisible={true}
                    headerTitle="pageTitles.loginPage"
                />
                <div className="content-wrapper">
                    <div className="container">
                        <div className="content-inner-wrapper">
                            <div className="row">
                                <div className="col-6">
                                    <div className="bodytext-app"><Translator
                                        content={"checkin.text.beforeLoginDescription"} /></div>
                                </div>
                                <div className="col-6">
                                    <form className="stayery-form" onSubmit={this.handleSubmit} autoComplete="off">
                                        {this.formInputTextWithLabel("fields.bookingId",
                                                                     'bookingId',
                                                                     this.state.booking.bookingId,
                                                                     'text'
                                        )}
                                        {this.formInputTextWithLabel("fields.lastName",
                                                                     'lastName',
                                                                     this.state.booking.lastName,
                                                                     'text'
                                        )}
                                        <button
                                            className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
                                            <Translator content={"checkin.buttons.submit"} /> <i
                                            className="icon icon-stayery-arrow-r" /></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    checkTime(result)
    {
        const arrival = dateParse(result.arrival);
        const now     = new Date();
        const hour    = new Date().getHours();

        const isArrivalDayAfter8oClock = differenceInCalendarDays(arrival, now) === 0 && hour >= 8;
        const isAfterArrivalDay        = differenceInCalendarDays(arrival, now) < 0;
        return isAfterArrivalDay || isArrivalDayAfter8oClock;
    }
}

export default Login;
