const langDe = {
    thisIso:         'de-DE',
    datePlaceholder: 'TT.MM.JJJJ',
    date: {
        "day": 'T',
        "month": 'M',
        "year": 'J'
    },
    pageTitles:      {
        "default":             "Stayery.",
        "mainPage":            "GOOD TO\nSEE YOU...",
        "checkoutPage":        "SAY GOODBYE.",
        "loginPage":           "Anmeldung.",
        "addressPage":         "Bestätige deine privaten\nDaten.",
        "signaturePage":       "and now. signature please.",
        "citytaxPage":         "Serious Business or just for fun?",
        coronaPage:            'COVID-19 Bestimmungen.',
        "roominformationPage": "Eingecheckt.\nDein Apartment\nwartet auf dich.",
        "consultfrontoffice":  "Eingecheckt.\nUnd nun?",
        "keycardPage":         "Du hast es\ngeschafft.",
        "lostcardPage":        "Verifizierung.",
        "thankyouPage":        "Thanks.",
        "helppage":            "Brauchst\ndu Hilfe?",
        "verificationCode":    "Bitte geben Sie den Verifizierungscode\nfür dieses Gerät ein",
        "privacy":             "Datenschutzhinweise"
    },

    buttons:            {
        confirmInformations: 'Daten bestätigen',
        confirm:             'Bestätigen'
    },
    start:              {
        "startLabel": "Hier kannst Du einchecken\nund Deine Keycard beziehen",
        "goback":     "Zurück"
    },
    main:               {
        "checkinLabel":     "CHECK-IN",
        "checkoutLabel":    "CHECK-OUT",
        "neuekeycardLabel": "NEUE KEYCARD",
        "lostcardLabel":    "KARTE VERLOREN",
        "description":      "... und willkommen in der STAYERY. Hier kannst Du einchecken\n auschecken oder eine verlorene Karte ersetzen.",
        "login":            "Zur Anmeldung",
        "shop":             "Lass mich rein",
        "checkoutbtn":      "Lass mich raus",
        "shopLable":        {
            "drinks":  "Drinks",
            "food":    "Food",
            "nonfood": "Non-Food"
        },

        "lostcard": "Bitte rette mich",
        "checkout": "Hier geht's zum Check-Out"
    },
    checkin:            {
        buttons:       {
            "submit":   "Los geht's",
            "getcard":  "Karte anfordern",
            "getcards": "Karten anfordern"
        },
        text:          {
            "beforeLoginDescription":    "Here we go – Du kennst das Spiel: Gib einfach in den Feldern Deine Buchungsnummer und Deinen Nachnamen ein, damit wir Dich einchecken können.\n\n\nDeine Buchungsnummer findest Du in der E-Mail oder WhatsApp, die wir Dir kurz vor Deiner Anreise gesendet haben. Solltest Du dennoch Schwierigkeiten mit der Anmeldung haben, ruf’ uns an und wir bringen Licht ins Dunkel.",
            "notforget":                 "Merk dir deine Apartmentnummer.",
            "roomnotice":                "Bitte notier Dir Deine Apartmentnummer, schreib sie Dir hinter's\nOhr oder tippe sie in Dein Phone. Hauptsache Du vergisst sie nicht!\nBereit für den nächsten Schritt? Deine Keycard wird von unserem\nEiswagen ausgespuckt.",
            "roomnoticetwoCardsAllowed": "Bitte notier Dir Deine Apartmentnummer, schreib sie Dir hinter's\nOhr oder tippe sie in Dein Phone. Hauptsache Du vergisst sie nicht!\nBereit für den nächsten Schritt? Wähle aus, ob Du eine oder direkt zwei Keycards brauchst"
        },
        errors:        {
            "notFound": "Abrufen der Buchung nicht möglich. Folgende Gründe sind möglich:\n- Dein Name oder Deine Buchungsnummer ist falsch\n- Du hast leider keine Reservierung bei uns"
        },
        "radioButton": {
            "one": "1 Karte",
            "two": "2 Karten"
        }
    },
    checkout:  {
        default: {
            buttons: "Ich mach nen Abgang!",
            text:  "Zeit zu gehen? Unser Check-Out ist easy peasy. Wirf einfach Deine Keycard in den gelben Briefkasten am Eingang und dann raus mit Dir. Deine Rechnung bekommst Du im Anschluss automatisch per E-Mail zugesendet.\n\nWenn Du einen Parkplatz hattest, parke vorerst aus der Tiefgarage aus, komme zurück in die STAYERY und wirf Deinen Transponder zusammen mit deiner Keycard in den gelben Briefkasten am Eingang.\n\nWir hoffen Du hattest einen very, very extraordinary Aufenthalt bei uns. Bis zum nächsten Mal!"
        },

        BRE:   {
            buttons: "ICK MACH'N ABJANG!",
            text: "Zeit zu gehen? Unser Check-Out ist easy peasy. Wirf einfach Deine Keycard in den gelben Briefkasten links am Eingang und dann raus mit Dir. Deine Rechnung bekommst Du im Anschluss automatisch per E-Mail zugesendet." +
                      "\n\nWenn Du einen Parkplatz hattest, parke vorerst aus der Tiefgarage aus, komme zurück in die STAYERY und wirf Deinen Transponder zusammen mit deiner Keycard in den gelben Briefkasten links am Eingang." +
                      "\n\nWir hoffen Du hattest einen very, very extraordinary Aufenthalt bei uns. Bis zum nächsten Mal!"
        },

        BER_FR:   {
            buttons: "ICK MACH'N ABJANG!",
            text: "Zeit zu gehen? Unser Check-Out ist easy peasy. Wirf einfach Deine Keycard in den gelben Briefkasten links am Eingang und dann raus mit Dir. Deine Rechnung bekommst Du im Anschluss automatisch per E-Mail zugesendet." +
                         "\n\nWenn Du einen Parkplatz hattest, parke vorerst aus der Tiefgarage aus, komme zurück in die STAYERY und wirf Deinen Transponder zusammen mit deiner Keycard in den gelben Briefkasten links am Eingang." +
                         "\n\nWir hoffen Du hattest einen very, very extraordinary Aufenthalt bei uns. Bis zum nächsten Mal!"
        },
        BIE_HB: {
            buttons:  "TSCHÖ!",
            text:   "Zeit zu gehen? Unser Check-Out ist easy peasy. Wirf einfach Deine Keycard in den gelben Briefkasten am Eingang und dann raus mit Dir. Deine Rechnung bekommst Du im Anschluss automatisch per E-Mail zugesendet.\n\nWenn Du einen Parkplatz hattest, wirf Deine Keycard auf dem Weg in die Tiefgarage einfach in den gelben Briefkasten im Untergeschoss. Du brauchst keine Karte für die Ausfahrt.\n\nWir hoffen Du hattest einen very, very extraordinary Aufenthalt bei uns. Bis zum nächsten Mal!"
        },
        CGN_EF: {
            buttons: "MACH ET JOT!",
            text:    "Zeit zu gehen? Unser Check-Out ist easy peasy. Wirf einfach Deine Keycard in den weißen Briefkasten am Eingang und dann raus mit Dir. Deine Rechnung bekommst Du im Anschluss automatisch per E-Mail zugesendet.\n\nWenn Du einen Parkplatz hattest, wirf Deine Keycard auf dem Weg in die Tiefgarage einfach in den gelben Briefkasten im Untergeschoss. Du brauchst keine Karte für die Ausfahrt.\n\nWir hoffen Du hattest einen very, very extraordinary Aufenthalt bei uns. Bis zum nächsten Mal!"
        },
        CGN_WS: {
            buttons: "MACH ET JOT!",
            text:    "Zeit zu gehen? Unser Check-Out ist easy peasy. Wirf einfach Deine Keycard in den gelben Briefkasten am Eingang und dann raus mit Dir. Deine Rechnung bekommst Du im Anschluss automatisch per E-Mail zugesendet.\n\nWenn Du einen Parkplatz hattest, wirf Deine Keycard einfach in den gelben Briefkasten am Eingang. Du brauchst keine Karte für die Ausfahrt.\n\nWir hoffen Du hattest einen very, very extraordinary Aufenthalt bei uns. Bis zum nächsten Mal!"
        },
        HBR_AW: {
            buttons: "Tschüs!",
            text:    "Zeit zu gehen? Unser Check-Out ist easy peasy. Wirf einfach Deine Keycard in den weißen Briefkasten am Eingang und dann raus mit Dir. Deine Rechnung bekommst Du im Anschluss automatisch per E-Mail zugesendet.\n\nWenn Du einen Parkplatz hattest, wirf Deine Keycard auf dem Weg in die Tiefgarage einfach in den gelben Briefkasten im Untergeschoss. Du brauchst keine Karte für die Ausfahrt.\n\nWir hoffen Du hattest einen very, very extraordinary Aufenthalt bei uns. Bis zum nächsten Mal!"
        },
        FRA_SH: {
            buttons: "GUUDE!",
            text:    "Zeit zu gehen? Unser Check-Out ist easy peasy. Wirf einfach Deine Keycard in den gelben Briefkasten am Eingang und dann raus mit Dir. Deine Rechnung bekommst Du im Anschluss automatisch per E-Mail zugesendet.\n\nWenn Du einen Parkplatz hattest, wirf Deine Keycard auf dem Weg in die Tiefgarage einfach in den gelben Briefkasten im Untergeschoss. Du brauchst keine Karte für die Ausfahrt.\n\nWir hoffen Du hattest einen very, very extraordinary Aufenthalt bei uns. Bis zum nächsten Mal!"
        },
        WOB_SW: {
            buttons: "TSCHÜÜS!",
            text:    "Zeit zu gehen? Unser Check-Out ist easy peasy. Wirf einfach Deine Keycard in den gelben Briefkasten im Flur zum Fahrstuhl und dann raus mit Dir. Deine Rechnung bekommst Du im Anschluss automatisch per E-Mail zugesendet.\n\nWir hoffen Du hattest einen very, very extraordinary Aufenthalt bei uns. Bis zum nächsten Mal!"
        },
        GTL_LW: {
            buttons: "MACH'S GUT!",
            text: "Zeit zu gehen? Unser Check-Out ist easy peasy. Wirf einfach Deine Keycard in den gelben Briefkasten am Eingang und dann raus mit Dir. Deine Rechnung bekommst Du im Anschluss automatisch per E-Mail zugesendet.\n\nWir hoffen Du hattest einen very, very extraordinary Aufenthalt bei uns. Bis zum nächsten Mal!"
        }

    },
    consultfrontoffice: {
        text: {
            headline: "Du bist ready.\nDein Apartment noch nicht.",
            text:     "Du bist entweder zu früh oder wir zu spät. Bitte wende Dich an\nDeine Hosts, um Dein Apartment zu beziehen. Sollten sie nicht vor\nOrt sein, ruf sie an:"
        }
    },
    address:            {
        "information": "Deine privaten Daten sind nicht korrekt? Dann einfach oben in den Feldern ändern! Danke Dir.",
        errors:        {
            "msg1":      "Fehler beim Check-In. Folgende Gründe sind möglich:\n-Das Check-in kann nur am Tag der Anreise durchgeführt werden\n-Ihre Buchung liegt in der Vergangenheit",
            "outOfTime": "Fehler beim Check-In.\n Der Check-In ist nur vom Ankunftstag bis zur gebuchten Abfahrtszeit möglich.",
            "farFuture": "Deine Buchung liegt mehr als 24 Stunden in der Zukunft. Komm doch später nochmal wieder um einzuchecken.",
            "notFound":  "Komisch! Wir finden Deine Buchung leider nicht. Versuch es noch einmal! Sonst ruf' uns an und wir bringen Licht ins Dunkel",
            "occupied":  "Unser Bier! Dein Apartment scheint noch belegt zu sein. Melde Dich bei unseren Hosts!",
            "inHouse":   "Fehler beim Check-In. Du bist bereits eingecheckt. Eine neue Karte erhältst Du über den Menüpunkt 'Karte verloren'.",
            wrongHotel:  {
                default: {
                    'title': 'OOOOOOPS, FALSCHE STAYERY...',
                    'body':  "Du bist gerade in der STAYERY <b>{{terminalPropertyName}}</b>, hast aber für <b>{{reservationPropertyName}}</b> gebucht. Bitte schau in Deiner Buchungsbestätigung nach der richtigen Adresse. Sonst ruf' uns an und wir bringen Licht ins Dunkel."
                }
            }
        }
    },
    signature:          {
        "text":        "Bitte setze Deine wundervolle Unterschrift in das Feld. Das deutsche Meldegesetz sieht diesen Schritt vor!",
        "information": "Laut Bundesmeldegesetz ist STAYERY als Beherbergungsbetrieb dazu verpflichtet, einen vom Gast ausgefüllten und unterschriebenen Meldeschein vorzuweisen. Die Richtigkeit der privaten Daten muss durch den Gast per Unterschrift bestätigt werden.",
        "button":      "weiter"
    },
    citytax:            {
        business:       "Geschäftlich",
        leisure:        "Privat",
        ready:          "Check-In",
        confirmPayment: "Check-In",
        nextButton:     "weiter",
        text:           {
            headline:          'Sag uns, ob Du privat oder geschäftlich reist!',
            headline_business: "Du reist geschäftlich. Wir stellen die Rechnung daher auf Deine Firma aus.\nBitte ergänze die Rechnungsdaten.",
            headline_leisure:  "Du bist privat in der Stadt. Deine Rechnung stellen wir daher auf Deine Privatadresse aus."
        },
        textLeisure:    {
            BER_FR: {
                1: "Für private und geschäftliche Aufenthalte fällt eine City Tax an. Diese Steuer wird an die Stadt Berlin abgeführt.\n\nWenn Du geschäftlich reist und Deine Buchung vor dem 1.\xa0April\xa02024 abgeschlossen hast, fällt für Dich keine\xa0City\xa0Tax an.",
                2: "Es fallen daher für Dich zusätzliche Kosten in Höhe von 5% des Apartmentpreises an. Allerdings nur für die ersten 21 Nächte."
            },
            BIE_HB: {
                1: "Für private Aufenthalte fällt eine City Tax an. Diese Steuer wird an die Stadt Bielefeld abgeführt.",
                2: "Es fallen daher für Dich zusätzliche Kosten in Höhe von 5% des Apartmentpreises an. Allerdings nur für die ersten 21 Nächte."
            },
            WOB_SW: {
                1: "Für private Aufenthalte fällt eine City Tax an. Diese Steuer wird an die Stadt Wolfsburg abgeführt.",
                2: "Es fallen daher für Dich zusätzliche Kosten in Höhe von 5% des Apartmentpreises an. Allerdings nur für die ersten 21 Nächte."
            },
            CGN_EF: {
                1: "Für private und geschäftliche Aufenthalte fällt eine City Tax an. Diese Steuer wird an die Stadt Köln abgeführt.",
                2: "Es fallen daher für Dich zusätzliche Kosten in Höhe von 5% des Apartmentpreises an."
            },
            FRA_SH: {
                1: "Für private Aufenthalte fällt eine City Tax an. Diese Steuer wird an die Stadt Frankfurt abgeführt.",
                2: "Es fallen daher für Dich zusätzliche Kosten in Höhe von 2€ pro Nacht pro Person zusätzlich zu Deinem Apartmentpreis an."
            },
            HBR_AW: {
                1: "Für private und geschäftliche Aufenthalte fällt eine City Tax an. Diese Steuer wird an die Stadt Bremen abgeführt.",
                2: "Es fallen daher für Dich zusätzliche Kosten in Höhe von 5% des Apartmentpreises an. Allerdings nur für die ersten 7 Nächte."
            },
            BRE:    {
                1: "Für private und geschäftliche Aufenthalte fällt eine City Tax an. Diese Steuer wird an die Stadt Bremen abgeführt.",
                2: "Es fallen daher für Dich zusätzliche Kosten in Höhe von 5% des Apartmentpreises an. Allerdings nur für die ersten 7 Nächte."
            },
            3:      "Du kommst nicht drum rum, brauchst aber auch nichts weiter zu tun. Deine Kreditkarte wird nach dem Check-In automatisch belastet."
        }
    },
    corona:             {
        DEFAULT: {
            intro:   'Aufgrund der aktuellen Coronaschutzverordnung ist bei Übernachtungen in Beherbergungsbetrieben ein 3G-Nachweis gefordert. Bitte bestätige, dass Du einen der folgenden Nachweise erbringen kannst:',
            impfung: 'Impfnachweis über eine vollständige Impfung',
            genesen: 'Nachweis über eine Genesung',
            test:    'Negatives Testergebnis',
            footer:  'Bestätige Deine Auswahl im nächsten Schritt mit Deiner Unterschrift.'
        }
    },
    keycard:            {
        "title":                "Schnapp dir deine Keycard.",
        "title_2":              "Schnapp dir deine Keycards.",
        "checkinDescription":   "Deine Keycard kommt frisch aus dem Druck. Nimm sie Dir aus der Ausgabe (schau mal auf Höhe Deiner Knie).\nSolltet Du Fragen haben, helfen Deine Hosts gerne weiter. Sollten sie nicht vor Ort sein, ruf sie an:",
        "checkinDescription_2": "Deine Keycards kommen frisch aus dem Druck. Nimm sie Dir aus der Ausgabe (schau mal auf Höhe Deiner Knie).\nSolltet Du Fragen haben, helfen Deine Hosts gerne weiter. Sollten sie nicht vor Ort sein, ruf sie an:",
        "button":               "Ok, hab ich mir geschnappt!"
    },
    lostcard:           {
        buttons: {
            "submit": "Karte anfordern"
        },
        text:    {
            "description": "No Keycard? No Problemo. Gib einfach Deine Daten ein und Du bekommst Deine neue Karte. Selbstverständlich erhalten alle, die zu zweit reisen, zwei Karten. Solltest Du Hilfe benötigen, ruf uns einfach an.",
            "ctaNote":     "Hinweis:",
            "cta":         "Sobald Deine neue Keycard in Verwendung ist, werden alle bisherigen Karten automatisch deaktiviert."
        },
        errors:  {
            "notFound": "Hmm. Das Abrufen Deiner Reservierung ist nicht möglich. Hast Du die Daten korrekt eingegeben? Versuch es noch einmal. Sonst ruf' uns an und wir bringen Licht ins Dunkel"
        }
    },
    roominformation:    {
        errors: {
            "createcard": "Beim Erstellen der Karte ist ein Fehler aufgetreten. Bitte versuche es erneut.",
            "4096":       'Oh. Da steckt wohl eine Karte fest. Geh doch bitte an das andere Terminal und versuch es unter „Karte verloren“ erneut.',
            "4098":       'Oh. Da lief etwas schief. Geh doch bitte an das andere Terminal und versuch es unter „Karte verloren“ erneut.',
            "4099":       'Oh. Da steckt wohl eine Karte fest. Geh doch bitte an das andere Terminal und versuch es unter „Karte verloren“ erneut.',
            "4100":       'Oh. Da lief etwas schief. Geh doch bitte an das andere Terminal und versuch es unter „Karte verloren“ erneut.',
            "4101":       'Oh. Da lief etwas schief. Geh doch bitte an das andere Terminal und versuch es unter „Karte verloren“ erneut.',
            "4103":       'Oh. Da stimmt etwas mit diesem Terminal nicht. Geh doch bitte an das zweite Terminal und versuch es unter „Karte verloren“ erneut.',
            "8194":       'Oh. Karten leer. Geh doch bitte an das andere Terminal und versuch es unter „Karte verloren“ erneut.'
        }
    },
    help:               {
        "text":   "Wenn Du Fragen hast oder es mal Probleme gibt,\nwende Dich einfach an einen unserer Hosts. Falls\nsie gerade nicht vor Ort sind, ruf uns einfach an.",
        "number": "+49 (0) 30 991 916 510"
    },
    verificationCode:   {
        buttons: {
            "submit": "absenden"
        }
    },
    fields:             {
        "title":       "Deine privaten Meldedaten",
        "bookingId":   "Buchungsnummer",
        "lastName":    "Nachname",
        "firstName":   "Vorname",
        "birthDate":   "Geburtsdatum",
        "email":       "E-Mail",
        "phone":       "Mobilnummer (bei Rückfragen)",
        "arrivalDate": "Anreisedatum",
        "address":     "Straße",
        "postalCode":  "PLZ",
        "city":        "Stadt",
        "country":     "Land",
        "roomnumber":  "Apartmentnummer",
        "signature":   "Unterschrift",
        "company":     "Firmenname"
    },
    messages:           {
        "timeout": "ACHTUNG: Wenn Du noch länger inaktiv bleibst, wirst Du in wenigen Sekunden ausgeloggt",
        "errors":  {
            "required":   "Pflichtfelder dürfen natürlich nicht leer sein",
            "email":      "Du hast keine gültige E-Mail angegeben",
            "errorTitle": " Oooooops, finde den Fehler..."
        }
    },
    countries:          {
        AF: "Afghanistan",
        AN: "Niederländische Antillen",
        AL: "Albanien",
        DZ: "Algerien",
        UM: "Amerikanisch-Ozeanien",
        AS: "Amerikanisch-Samoa",
        VI: "Amerikanische Jungferninseln",
        AD: "Andorra",
        AO: "Angola",
        AI: "Anguilla",
        AQ: "Antarktis",
        AG: "Antigua und Barbuda",
        AR: "Argentinien",
        AM: "Armenien",
        AW: "Aruba",
        AZ: "Aserbaidschan",
        AU: "Australien",
        BS: "Bahamas",
        BH: "Bahrain",
        BD: "Bangladesch",
        BB: "Barbados",
        BE: "Belgien",
        BZ: "Belize",
        BJ: "Benin",
        BM: "Bermuda",
        BT: "Bhutan",
        BO: "Bolivien",
        BQ: "Bonaire, Sint Eustatius und Saba",
        BA: "Bosnien und Herzegowina",
        BW: "Botsuana",
        BV: "Bouvetinsel",
        BR: "Brasilien",
        VG: "Britische Jungferninseln",
        IO: "Britisches Territorium im Indischen Ozean",
        BN: "Brunei Darussalam",
        BG: "Bulgarien",
        BF: "Burkina Faso",
        BI: "Burundi",
        CL: "Chile",
        CN: "China",
        CK: "Cookinseln",
        CR: "Costa Rica",
        CW: "Curaçao",
        CD: "Demokratische Republik Kongo",
        KP: "Demokratische Volksrepublik Korea",
        DE: "Deutschland",
        DM: "Dominica",
        DO: "Dominikanische Republik",
        DJ: "Dschibuti",
        DK: "Dänemark",
        EC: "Ecuador",
        SV: "El Salvador",
        CI: "Elfenbeinküste",
        ER: "Eritrea",
        EE: "Estland",
        FK: "Falklandinseln",
        FJ: "Fidschi",
        FI: "Finnland",
        FR: "Frankreich",
        GF: "Französisch-Guayana",
        PF: "Französisch-Polynesien",
        TF: "Französische Süd- und Antarktisgebiete",
        FO: "Färöer",
        GA: "Gabun",
        GM: "Gambia",
        GE: "Georgien",
        GH: "Ghana",
        GI: "Gibraltar",
        GD: "Grenada",
        GR: "Griechenland",
        GB: "Großbritannien",
        GL: "Grönland",
        GP: "Guadeloupe",
        GU: "Guam",
        GT: "Guatemala",
        GG: "Guernsey",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HT: "Haiti",
        HM: "Heard und McDonaldinseln",
        HN: "Honduras",
        HK: "Hong Kong S.A.R., China",
        IN: "Indien",
        ID: "Indonesien",
        IM: "Insel Man",
        IQ: "Irak",
        IR: "Iran",
        IE: "Irland",
        IS: "Island",
        IL: "Israel",
        IT: "Italien",
        JM: "Jamaika",
        JP: "Japan",
        YE: "Jemen",
        JE: "Jersey",
        JO: "Jordanien",
        KY: "Kaimaninseln",
        KH: "Kambodscha",
        CM: "Kamerun",
        CA: "Kanada",
        CV: "Kap Verde",
        KZ: "Kasachstan",
        QA: "Katar",
        KE: "Kenia",
        KG: "Kirgisistan",
        KI: "Kiribati",
        CC: "Kokosinseln (Keeling)",
        CO: "Kolumbien",
        KM: "Komoren",
        CG: "Kongo",
        HR: "Kroatien",
        CU: "Kuba",
        KW: "Kuwait",
        LA: "Laos",
        LS: "Lesotho",
        LV: "Lettland",
        LB: "Libanon",
        LR: "Liberia",
        LY: "Libyen",
        LI: "Liechtenstein",
        LT: "Litauen",
        LU: "Luxemburg",
        MO: "Macau S.A.R., China",
        MG: "Madagaskar",
        MW: "Malawi",
        MY: "Malaysia",
        MV: "Malediven",
        ML: "Mali",
        MT: "Malta",
        MA: "Marokko",
        MH: "Marschallinseln",
        MQ: "Martinique",
        MR: "Mauretanien",
        MU: "Mauritius",
        YT: "Mayotte",
        MK: "Mazedonien",
        MX: "Mexiko",
        FM: "Mikronesien",
        MC: "Monaco",
        MN: "Mongolei",
        ME: "Montenegro",
        MS: "Montserrat",
        MZ: "Mosambik",
        MM: "Myanmar",
        NA: "Namibia",
        NR: "Nauru",
        NP: "Nepal",
        NC: "Neukaledonien",
        NZ: "Neuseeland",
        NI: "Nicaragua",
        NL: "Niederlande",
        NE: "Niger",
        NG: "Nigeria",
        NU: "Niue",
        NF: "Norfolkinsel",
        NO: "Norwegen",
        MP: "Nördliche Marianen",
        OM: "Oman",
        TL: "Osttimor",
        PK: "Pakistan",
        PW: "Palau",
        PS: "Palästina",
        PA: "Panama",
        PG: "Papua-Neuguinea",
        PY: "Paraguay",
        PE: "Peru",
        PH: "Philippinen",
        PN: "Pitcairn",
        PL: "Polen",
        PT: "Portugal",
        PR: "Puerto Rico",
        KR: "Republik Korea",
        MD: "Republik Moldau",
        RE: "Reunion",
        RW: "Ruanda",
        RO: "Rumänien",
        RU: "Russische Föderation",
        BL: "Saint-Barthélemy",
        MF: "Saint-Martin",
        SB: "Salomonen",
        ZM: "Sambia",
        WS: "Samoa",
        SM: "San Marino",
        SA: "Saudi-Arabien",
        SE: "Schweden",
        CH: "Schweiz",
        SN: "Senegal",
        RS: "Serbien",
        SC: "Seychellen",
        SL: "Sierra Leone",
        ZW: "Simbabwe",
        SG: "Singapur",
        SX: "Sint Maarten",
        SK: "Slowakei",
        SI: "Slowenien",
        SO: "Somalia",
        ES: "Spanien",
        LK: "Sri Lanka",
        SH: "St. Helena",
        KN: "St. Kitts und Nevis",
        LC: "St. Lucia",
        PM: "St. Pierre und Miquelon",
        VC: "St. Vinzent und die Grenadinen",
        SD: "Sudan",
        SR: "Suriname",
        SJ: "Svalbard und Jan Mayen",
        SZ: "Swasiland",
        SY: "Syrien",
        ST: "São Tomé und Príncipe",
        ZA: "Südafrika",
        GS: "Südgeorgien und die Südlichen Sandwichinseln",
        SS: "Südsudan",
        TJ: "Tadschikistan",
        TW: "Taiwan",
        TZ: "Tansania",
        TH: "Thailand",
        TG: "Togo",
        TK: "Tokelau",
        TO: "Tonga",
        TT: "Trinidad und Tobago",
        TD: "Tschad",
        CZ: "Tschechische Republik",
        TN: "Tunesien",
        TM: "Turkmenistan",
        TC: "Turks- und Caicosinseln",
        TV: "Tuvalu",
        TR: "Türkei",
        UG: "Uganda",
        UA: "Ukraine",
        HU: "Ungarn",
        UY: "Uruguay",
        UZ: "Usbekistan",
        VU: "Vanuatu",
        VA: "Vatikanstadt",
        VE: "Venezuela",
        AE: "Vereinigte Arabische Emirate",
        US: "Vereinigte Staaten von Amerika",
        VN: "Vietnam",
        WF: "Wallis und Futuna",
        CX: "Weihnachtsinsel",
        BY: "Weißrussland",
        EH: "Westsahara",
        CF: "Zentralafrikanische Republik",
        CY: "Zypern",
        EG: "Ägypten",
        GQ: "Äquatorialguinea",
        ET: "Äthiopien",
        AX: "Ålandinseln",
        AT: "Österreich"
    }
};

export default langDe;
