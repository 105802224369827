import React, { Component } from 'react';

class PrivacyContentEN extends Component {

    render() {

        return (
            <div className="content-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-9">
                            <div className="privacy-block">
                                <h2 className="title-font">1. General Information</h2>
                                <p className="_uppercase">Information about the controller:</p>

                                <p>
                                    <span className="_uppercase _extra-small">Company </span>
                                    <span className="title-font">Bd Apartment gmbh („Stayery“)</span>
                                    <br />
                                    <span className="_uppercase _extra-small">Address </span>
                                    <span
                                        className="title-font">Schönhauser Allee 43A, 10435 Berlin</span>
                                    <br />
                                    <span
                                        className="_uppercase _extra-small">Contact details for data protection officer</span>
                                    <span
                                        className="title-font">Datenschutz@stayery.de</span>
                                </p>
                            </div>
                            <div className="privacy-block">
                                <h2 className="title-font">2. Specific information about your stay with us</h2>

                                <p>
                                    <span className="_uppercase">Affected data:</span> <br />
                                    Surname, first name, address, date of birth, contact information (e-mail address,
                                    telephone number), booking number, type of trip (business or private), company and
                                    company address if applicable
                                </p>

                                <p>
                                    <span className="_uppercase">Processing Purpose:</span> <br />
                                    Organisation and execution of the stay
                                </p>

                                <p>
                                    <span className="_uppercase">Legal basis:</span> <br />
                                    Necessity for the performance of a contract (Art. 6 par. 1 lit. b GDPR)
                                </p>

                                <p>
                                    <span className="_uppercase">Categories of recipients:</span> <br />
                                    Public authorities in the event of priority legislation. External service providers
                                    or other contractors, especially for hosting and booking management.
                                    Other external bodies in so far as the data subject has given his consent or a
                                    transmission is permitted due to a prevailing interest.
                                </p>

                                <p>
                                    <span className="_uppercase">Third-country transfers:</span> <br />
                                    None
                                </p>

                                <p>
                                    <span className="_uppercase">Duration of data storage:</span> <br />
                                    The duration of data storage depends on the statutory stor-age requirements and is
                                    usually 10 years.
                                </p>
                            </div>
                            <div className="privacy-block">
                                <h2 className="title-font"> 3. Specific information about CCTV</h2>

                                <p>
                                    <span className="_uppercase">Affected data:</span> <br />
                                    Film recordings
                                </p>

                                <p>
                                    <span className="_uppercase">Processing Purpose:</span> <br />
                                    Property protection of the STAYERY house
                                </p>

                                <p>
                                    <span className="_uppercase">Legal basis:</span> <br />
                                    Art. 6 par. 1 f GDPR (STAYERY‘s economic and legal inter-ests, in particular
                                    preventing and investigating theft and vandalism)
                                </p>

                                <p>
                                    <span className="_uppercase">Categories of recipients:</span> <br />
                                    Public authorities in the event of priority legislation
                                </p>

                                <p>
                                    <span className="_uppercase">Third-country transfers:</span> <br />
                                    None
                                </p>

                                <p>
                                    <span className="_uppercase">Duration of data storage:</span> <br />
                                    Data is generally stored for 72 hours. – If anomalies occur within this period, the
                                    corresponding material can be stored for longer.
                                </p>
                            </div>
                            <div>
                                <h2 className="title-font">Further information and contacts</h2>

                                <p>
                                    In addition, you may invoke your rights to correction or deletion at any time, to
                                    restrict processing, to object to processing, and to data portability. Here you will
                                    find the option to contact us by email: datenschutz@stayery.de. You also have the
                                    right to contact the data protection supervisory authority for complaints.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyContentEN;
