import React, {Component} from 'react';
import Translator         from "../../components/Translator";
import LoadingSpinner     from "../LoadingSpinner/LoadingSpinner";
import * as Sentry        from '@sentry/browser';
import {locale}           from "moment";

class TerminalVerification extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            verificationCode: '', // TEST-1
            isSpinnerVisible: false
        };

        this.handleSubmit           = this.handleSubmit.bind(this);
        this.handleChange           = this.handleChange.bind(this);
        this.submitVerificationCode = this.submitVerificationCode.bind(this);
    }

    componentDidMount = () => {
        // ?verificationCode=TEST-1
        let verificationCode = new URLSearchParams(window.location.search).get('verificationCode');
        console.log('componentDidMount::verificationCode',verificationCode);
        if (verificationCode !== null && verificationCode !== '') {
            this.setState({verificationCode: verificationCode.toUpperCase()}, () => {
                this.submitVerificationCode();
            });
        }
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();

        this.submitVerificationCode();
    }

    handleChange(event)
    {
        this.setState({verificationCode: (event.target.value).toUpperCase()});
    }

    submitVerificationCode()
    {
        localStorage.removeItem('verificationCode');

        if (this.state.verificationCode !== '') {
            this.setState({isSpinnerVisible: true});

            this.props.apiClient.verifyTerminalCode(this.state.verificationCode).then(response => {

                if (typeof response === 'undefined' || response === null) {
                    response = {
                        error: 'createcard'
                    }
                    Sentry.captureEvent({
                                            message: 'Response was null or undefined'
                                        });
                }

                this.setState({isSpinnerVisible: false});
                if (response.error) {
                    return false;
                } else if ((response.success ?? false) === true) {
                    localStorage.setItem('verificationCode', this.state.verificationCode);
                    localStorage.setItem('hotline', response.hotline ?? '');
                    localStorage.setItem('citytaxMode', response.citytaxMode ?? '');
                    localStorage.setItem('enableCheckout', response.enableCheckout ?? '');
                    localStorage.setItem('hotelId', response.hotelId ?? '');
                    this.props.checkStorageForTerminalCode();
                }

            }).catch((err) => {
                console.warn(err);
                Sentry.captureException(err);
                this.setState({isSpinnerVisible: false});
            });
        }
    }

    render()
    {
        return (<div className="page-terminalVerification">
            <LoadingSpinner
                isSpinnerVisible={this.state.isSpinnerVisible}
            />
            <div className="page-inner-wrapper">
                <div className="content-wrapper">
                    <div className="bodytext-app"><span className="bigger"><Translator
                        content={"pageTitles.verificationCode"}/></span>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <form className="stayery-form" onSubmit={this.handleSubmit} autoComplete="off">
                                <div className="form-group">
                                    <div className="">
                                        <input type="text"
                                               className="form-control"
                                               value={this.state.verificationCode}
                                               onChange={this.handleChange}
                                               autoComplete="off"
                                               placeholder=" "/>
                                        <i className="icon icon-stayery-group hidden"/>
                                    </div>
                                </div>
                                <button
                                    className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
                                    <Translator content={"verificationCode.buttons.submit"}/> <i
                                    className="icon icon-stayery-arrow-r"/></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default TerminalVerification;
