import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from "../Header/Header";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import {Redirect} from "react-router";

var animationData = require('./Thank_You_Animation.json');

class Thankyou extends Component {

    constructor(props){
        super(props);

        this.state = {
            redirect : false
        };

        setTimeout( () => {this.setState({redirect : true})}, 5000);
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect refresh={true} to='/' />);
        }

        return (
            <div className="page-thankyou">
                <div className="page-inner-wrapper">
                    <Header
                        headerTitle='pageTitles.thankyouPage'
                        deactivateLanguageSwitcher={true}
                    />
                    <div className={'content-wrapper'} onClick={()=>{this.setState({redirect : true})}}>
                        <Player
                            autoplay
                            loop
                            src={animationData}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Thankyou;
