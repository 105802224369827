import {FC, useEffect, useRef} from "react";
import {WheelPickerItem}       from "./WheelPickerItem";

type WheelPickerProps = {
    initialFocusIndex: number,
    value: number | undefined,
    onChange: (newValue: number) => void,
    options: { key: string, value: Date, label: string }[],
    itemHeight?: number,
    containerHeight?: number,
    width?: number,
    updateOnScroll?: boolean
}

export const WheelPicker: FC<WheelPickerProps> = (props) => {
    const {
              initialFocusIndex,
              value,
              onChange,
              options,
              itemHeight      = 32,
              containerHeight = 150,
              width           = 45,
              updateOnScroll  = false
          } = props;

    const itemRefs    = useRef([]);
    const isScrolling = useRef(false);
    const isAnimating = useRef(false);

    const handleSelect = (index: number) => {
        if (value === index) {
            return;
        }

        const scrollOptions: ScrollIntoViewOptions = {
            block:    "center",
            behavior: 'smooth'
        };
        itemRefs?.current[index]?.scrollIntoView(scrollOptions);
        onChange(index);
    };

    const handleScroll = (event) => {
        if (!isAnimating.current) {
            isAnimating.current = true;

            requestAnimationFrame(() => {
                const scrollTop       = Math.max(event.target.scrollTop, 0);
                const selectedElement = Math.min(
                    Math.max(Math.floor(scrollTop / itemHeight), 0),
                    options.length - 1
                );

                if (updateOnScroll) {
                    window.clearTimeout(isScrolling.current);
                }

                if (updateOnScroll) {
                    isScrolling.current = setTimeout(function() {
                        onChange(selectedElement);
                    }, 20);
                }

                isAnimating.current = false;
            });
        }
    }

    // at the start scroll to selected item
    useEffect(() => {
        const scrollOptions: ScrollIntoViewOptions = {
            block: "center"
        };
        itemRefs?.current[initialFocusIndex]?.scrollIntoView(scrollOptions);
    }, []);

    return (
        <Wheel
            height={containerHeight}
            width={width}
        >
            <ul
                onScroll={handleScroll}
                className={'wheel-items'}
                style={{
                    padding: `${containerHeight / 2}px 0`
                }}
            >
                {options.map((option, index) => (
                    <WheelPickerItem
                        className={'wheel-item' + (value === index ? ' _selected' : '')}
                        key={option.key}
                        setRef={(node) => (itemRefs.current[index] = node)}
                        value={index}
                        label={option.label}
                        onSelect={handleSelect}
                        style={{
                            height: `${itemHeight}px`
                        }}
                    />
                ))}
            </ul>
        </Wheel>
    );
}

type WheelProps = {
    width: number;
    height: number;
};

const Wheel: FC<WheelProps> = (props) => {
    const {height, width, children} = props;

    return (
        <div
            className={'wheel-container'}
            style={{
                width:  `${width}px`,
                height: `${height}px`
            }}
        >
            {children}
        </div>
    );
}
