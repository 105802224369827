import React, { Component } from 'react';

class PrivacyContentDE extends Component {

    render() {

        return (
            <div className="content-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-9">
                            <div className="privacy-block">
                                <h2 className="title-font">1. Allgemeine Angaben</h2>
                                <p className="_uppercase">Angaben zur Verantwortlichen Stelle:</p>

                                <p>
                                    <span className="_uppercase _extra-small">Unternehmen </span>
                                    <span className="title-font">Bd Apartment gmbh („Stayery“)</span>
                                    <br/>
                                    <span className="_uppercase _extra-small">Adresse </span>
                                    <span
                                        className="title-font">Schönhauser Allee 43A, 10435 Berlin</span>
                                    <br/>
                                    <span
                                        className="_uppercase _extra-small">Kontaktdaten Datenschutzbeauftragter </span>
                                    <span
                                        className="title-font">Datenschutz@stayery.de</span>
                                </p>
                            </div>
                            <div className="privacy-block">
                                <h2 className="title-font">2. Angaben zur Verarbeitung im Rahmen Ihres
                                    Aufenthalts</h2>

                                <p>
                                    <span className="_uppercase">Betroffene Daten:</span> <br/>
                                    Name, Vorname, Adresse, Geburtsdatum, Kontaktinformationen (E-Mail-Adresse,
                                    Telefonnummer),
                                    Buchungsnummer, Art der Reise (geschäftlich bzw. privat), ggf. Firma und
                                    Firmenadresse
                                </p>

                                <p>
                                    <span className="_uppercase">Verarbeitungszweck:</span> <br/>
                                    Organisation und Durchführung des Aufenthalts
                                </p>

                                <p>
                                    <span className="_uppercase">Rechtsgrundlage:</span> <br/>
                                    Vertragserforderlichkeit (Art. 6 Abs. 1 lit. b DSGVO)
                                </p>

                                <p>
                                    <span className="_uppercase">Kategorien von Empfängern:</span> <br/>
                                    Öffentliche Stellen bei Vorliegen vorrangiger Rechts-vorschriften.
                                    Dienstleister für Hosting und Buchungsverwaltung. Weitere externe Stellen soweit
                                    der
                                    Betroffene
                                    seine
                                    Einwilligung erteilt hat oder eine Übermittlung aus überwiegen-dem Interesse
                                    zulässig ist.
                                </p>

                                <p>
                                    <span className="_uppercase">Drittlandtransfers:</span> <br/>
                                    Keiner
                                </p>

                                <p>
                                    <span className="_uppercase">Dauer Datenspeicherung:</span> <br/>
                                    Die Datenspeicherung orientiert sich an den gesetzlichen Aufbewahrungsfristen.
                                </p>
                            </div>
                            <div className="privacy-block">
                                <h2 className="title-font"> 3. Angaben zur eingesetzten Videoüberwachung</h2>

                                <p>
                                    <span className="_uppercase">Betroffene Daten:</span> <br/>
                                    Filmaufnahmen
                                </p>

                                <p>
                                    <span className="_uppercase">Verarbeitungszweck:</span> <br/>
                                    Eigentumsschutz des STAYERY-Hause
                                </p>

                                <p>
                                    <span className="_uppercase">Rechtsgrundlage:</span> <br/>
                                    Art. 6 Abs. 1 lit. f DSGVO (Wirtschaftliche und rechtliche Interessen von
                                    STAYERY,
                                    insbesondere
                                    Verhindern bzw. Auf-klären von Diebstahl und Vandalismus)
                                </p>

                                <p>
                                    <span className="_uppercase">Kategorien von Empfängern:</span> <br/>
                                    Öffentliche Stellen bei Vorliegen vorrangiger Rechtsvorschriften
                                </p>

                                <p>
                                    <span className="_uppercase">Drittlandtransfers:</span> <br/>
                                    Keiner
                                </p>

                                <p>
                                    <span className="_uppercase">Dauer Datenspeicherung:</span> <br/>
                                    Die Datenspeicherung beträgt grundsätzlich 72 Stunden. – Sollten innerhalb der
                                    Frist
                                    Auffälligkeiten
                                    auftreten, kann das entsprechende Material länger aufbewahrt werden.
                                </p>
                            </div>
                            <div>
                                <h2 className="title-font">Weitere Informationen und Kontakte</h2>

                                <p>
                                    Darüber hinaus können Sie jederzeit ihre Ansprüche auf Auskunft, Berichtigung
                                    oder
                                    Löschung
                                    oder auf
                                    Einschränkung der Verarbeitung oder der Wahrnehmung Ihres Widerspruchsrechts
                                    gegen
                                    die
                                    Verarbeitung
                                    sowie das Recht auf Datenübertragbarkeit geltend machen. Wenn Sie uns per Brief
                                    kontaktieren
                                    möchten,
                                    verwenden Sie gerne die oben genannte Adresse. Für E-Mails steht Ihnen bei
                                    Datenschutz-Anfragen das
                                    Postfach datenschutz@stayery.de zur Verfügung. - Sie haben ferner das Recht,
                                    sich
                                    bei
                                    Beschwerden an
                                    die
                                    Datenschutz-Aufsichtsbehörde zu wenden.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyContentDE;
