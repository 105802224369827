import {FC}            from "react";
import {CSSProperties} from "react";

type WheelPickerItemProps = {
    value: number,
    label: string,
    onSelect: (value: number) => boolean,
    setRef: (refObj: {}) => void,
    style?: CSSProperties,
    className?: string
}

export const WheelPickerItem: FC<WheelPickerItemProps> = (props) => {
    const {
              value,
              label,
              onSelect,
              style,
              setRef,
              className = ''
          } = props;

    const handleOnClick = () => {
        onSelect(value);
    }

    return (
        <li
            ref={(node) => setRef(node)}
            onClick={handleOnClick}
            className={className}
            style={style}
        >
            <div onClick={handleOnClick}>{label}</div>
        </li>
    );
}
