import React, {Component} from 'react';
import ScrollArea         from 'react-scrollbar';
import PrivacyContentDE   from "./PrivacyContentDE";
import counterpart        from "counterpart";
import PrivacyContentEN   from "./PrivacyContentEN";

class Privacy extends Component
{

    constructor(props)
    {
        super(props);

        this.state      = {
            listLongerThanContainer: false,
            scrollPosition:          1
        };
        this.scrollArea = React.createRef();
    }


    render()
    {
        const lang              = counterpart.getLocale();
        let classNameScrollArea = "area";

        if (this.state.listLongerThanContainer) {
            classNameScrollArea += " _listLongerThanContainer";
        }

        switch (this.state.scrollPosition) {
            case -1:
                classNameScrollArea += " _showUpArrow";
                break;
            case 0:
                classNameScrollArea += " _showUpArrow _showDownArrow";
                break;
            case 1:
                classNameScrollArea += " _showDownArrow";
                break;
            default:
                break;
        }

        const scrollArea = <ScrollArea
            ref={this.scrollArea}
            className={classNameScrollArea}
            horizontal={false}
            onScroll={this.onScroll}
        >
            {lang === 'de' ? <PrivacyContentDE/> : <PrivacyContentEN/>}
        </ScrollArea>;

        if (!this.props.show) {
            return null;
        }

        return (<div className="page-privacy">
                <div className="page-inner-wrapper">
                    {scrollArea}
                    <i className={'__scroll-down icon icon-stayery-arrow-d'}/>
                </div>
            </div>);
    }

    onScroll = (value) => {
        console.log(value);
        let containerHeight = value.containerHeight;
        let topPosition     = value.topPosition;
        let realHeight      = value.realHeight;

        let listLongerThanContainer = realHeight > containerHeight;
        let scrollPosition          = -1;
        let threshold               = 100;

        if (this.state.scrollPosition !== -1) {
            if (topPosition) {
                let b = containerHeight + topPosition < realHeight - threshold;
                if (topPosition > threshold && b) {
                    scrollPosition = 0;
                } else if (b) {
                    scrollPosition = 1;
                } else {
                    scrollPosition = -1;
                }
            } else {
                scrollPosition = 1;
            }
        }

        this.setState({
                          listLongerThanContainer: listLongerThanContainer,
                          scrollPosition:          scrollPosition
                      });
    }
}

export default Privacy;
