import React, {FC, useEffect, useState} from "react";
import {format, isValid, parseISO}      from "date-fns";
import {DateWheelPicker}                from "./DateWheelPicker";
import {
    autoUpdate, flip, FloatingFocusManager, offset, shift, useClick, useDismiss, useFloating, useInteractions, useRole
}                                       from "@floating-ui/react";
import Translator                       from "../Translator";
import {DatePickerHeader}               from "./DatePickerHeader";

type DateObject = {
    day: number | undefined,
    month: number | undefined,
    year: number | undefined
};

const defaultDateObject = {day: undefined, month: undefined, year: undefined};
const createDateObject  = (dateString: string) => {
    const date = parseISO(dateString);
    if (isValid(date)) {
        return {
            day:   date.getDate(),
            month: date.getMonth(),
            year:  date.getFullYear()
        };
    } else {
        return {...defaultDateObject};
    }
};

const ContainerHeight = 200;
const ItemHeight      = 45;
const PickerWidths    = {
    day:   60,
    month: 60,
    year:  80
};

type DatePickerProps = {
    label: string,
    name: string,
    value: string,
    onChange: (event: { target: { name: string, value: string } }) => void,
    dateFormat?: string,
    error: boolean,
}

export const DatePicker: FC<DatePickerProps> = (props) => {
    const {
              label,
              name,
              value,
              onChange,
              dateFormat = 'yyyy-MM-dd',
              error
          } = props;

    const [dateObject, setDateObject] = useState(createDateObject(value));

    useEffect(() => {
        setDateObject(createDateObject(value));
    }, [value]);

    const hasValidValue = (value) => {
        return value.day !== undefined && value.month !== undefined && value.year !== undefined;
    }

    const toDate = (value) => {
        if (!hasValidValue(value)) {
            return new Date();
        }
        return new Date(value.year, value.month, value.day);
    }

    // ##### floating ui start #####

    const [isOpen, setIsOpen] = useState(false);

    const {refs, floatingStyles, context} = useFloating({
                                                            open:                 isOpen,
                                                            onOpenChange:         setIsOpen,
                                                            middleware:           [offset(8), flip(), shift()],
                                                            whileElementsMounted: autoUpdate,
                                                            placement:            'bottom-start'
                                                        });

    const click   = useClick(context);
    const dismiss = useDismiss(context);
    const role    = useRole(context);

    // Merge all the interactions into prop getters
    const {getReferenceProps, getFloatingProps} = useInteractions([
                                                                      click,
                                                                      dismiss,
                                                                      role
                                                                  ]);

    // ##### floating ui end #####

    let formControlClassName = 'datepicker-button form-control';
    if (error) {
        formControlClassName += " is-invalid";
    }

    const handleOnChange = (newValue: DateObject) => {
        setDateObject(newValue);

        if (hasValidValue(newValue)) {
            const date = toDate(newValue);
            if (isValid(date)) {
                const dateString = format(date, dateFormat)
                const event      = {
                    target: {
                        name:  name,
                        value: dateString
                    }
                };
                onChange(event);
            }
        }
    };

    return (
        <div className="form-group">
            <label className="control-label" style={{"whiteSpace": "nowrap"}}>
                <Translator content={label} />
            </label>
            <button
                type={'button'}
                className={formControlClassName}
                ref={refs.setReference}
                {...getReferenceProps()}
            >
                {hasValidValue(dateObject)
                 ? format(toDate(dateObject), 'dd.MM.yyyy')
                 : <div className={'datepicker-button _placeholder'}><Translator content={'datePlaceholder'} /></div>
                }
            </button>
            {isOpen && (<FloatingFocusManager context={context}>
                <div
                    className={'datepicker-popover'}
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                >
                    <DatePickerHeader widths={PickerWidths} />
                    <DateWheelPicker
                        value={dateObject}
                        focusDate={new Date()}
                        onChange={handleOnChange}

                        containerHeight={ContainerHeight}
                        itemHeight={ItemHeight}
                        widths={PickerWidths}
                    />
                </div>
            </FloatingFocusManager>)
            }
        </div>
    );
}
