// @flow
import React, {Component} from 'react';
import Header             from "../Header/Header";
import {Redirect}         from "react-router";
import Translator         from "../../components/Translator";
import LoadingSpinner     from "../LoadingSpinner/LoadingSpinner";
import ErrorHandler       from "../ErrorHandler/ErrorHandler";
import * as Sentry        from '@sentry/browser';

class RoomInformation extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            errorMessage:     '',
            showErrorMessage: false,
            isSpinnerVisible: false,
            cardNum:          1
        };

        this.submitRequestKeyCard = this.submitRequestKeyCard.bind(this);
    }

    submitRequestKeyCard()
    {
        this.setState({isSpinnerVisible: true});

        Sentry.addBreadcrumb({message: 'Reservation ' + (this.props.reservation?.id ?? '-') + ' create keycard'});

        const roomNr   = String(this.props.location.state.roomname);
        const checkIn  = this.props.reservation.arrival;
        const checkOut = this.props.reservation.departure;
        const cardNum  = this.state.cardNum;
        this.props.apiClient.requestKeyCard(roomNr, checkIn, checkOut, cardNum)
            .then(response => {
                Sentry.addBreadcrumb({
                                         message: 'Reservation ' + (this.props.reservation?.id ?? '-') +
                                                      ' keycard created'
                                     });

                if (typeof response === 'undefined' || response === null) {
                    response = {
                        error: 'createcard'
                    }
                    Sentry.captureEvent({
                                            message: 'Response was null or undefined'
                                        });
                }

                this.setState({isSpinnerVisible: false});
                if (response.error) {
                    let errorMsg = 'roominformation.errors.createcard';
                    if (typeof response.reason !== 'undefined') {
                        switch (response.reason) {
                            case 4096:
                            case 4098:
                            case 4099:
                            case 4100:
                            case 4101:
                            case 4103:
                            case 8194:
                                errorMsg = `roominformation.errors.${response.reason}`;
                                break;
                            default:
                                errorMsg = 'roominformation.errors.createcard';
                                break;
                        }
                    }
                    this.showErrorMessage(errorMsg);

                    return false;
                } else {
                    console.log(response);
                    Sentry.addBreadcrumb({
                                             message: 'Reservation ' + (this.props.reservation?.id ?? '-') +
                                                          ' roominformation => keycard'
                                         });
                    this.props.history.push({
                                                pathname: 'keycard'
                                            }, {
                                                roomNumber: this.props.location.state.roomname,
                                                cardNum:    cardNum
                                            });
                }

            }).catch((err) => {
            // FIXME: unhandled Exception
            Sentry.captureException(err);
            this.setState({isSpinnerVisible: false});
        });
    }

    showErrorMessage(translationId)
    {
        this.setState({
                          errorMessage:     translationId,
                          showErrorMessage: true
                      });
        setTimeout(() => {
            this.setState({showErrorMessage: false})
        }, 8000);
    }

    render()
    {
        if (!this.props.reservation) {
            return <Redirect to={'/'} refresh={true}/>;
        }

        const twoCardsAllowed = this.props.reservation?._meta?.twoCardsAllowed ?? false;

        return (<div className="page-roominformation">
            {(this.state.showErrorMessage) ? <ErrorHandler errorMessage={this.state.errorMessage}/> : false}
            <LoadingSpinner
                isSpinnerVisible={this.state.isSpinnerVisible}
            />
            <div className="page-inner-wrapper">
                <Header
                    headerTitle="pageTitles.roominformationPage"
                />
                <div className="content-wrapper">
                    <div className="container">
                        <div className="room-information">
                            <div className="row">
                                <div className="col-6">
                                    <div className="room-number">
                                        <div className="room-number-inner">
                                            {this.props.location.state?.roomname ?? 'TEST'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="room-information">
                                        <h2 className="h2-heading">
                                            <Translator content={"checkin.text.notforget"}/>
                                        </h2>
                                        <div className="description">
                                            {twoCardsAllowed ? (
                                                <Translator content={"checkin.text.roomnoticetwoCardsAllowed"}/>) : (
                                                 <Translator content={"checkin.text.roomnotice"}/>)}
                                        </div>

                                        {twoCardsAllowed && (<div className="custom-radio-container-wrap">
                                            <label htmlFor="travelReason-leisure"
                                                   className="custom-radio-container">
                                                <Translator className="__label" content="checkin.radioButton.one"/>
                                                <input
                                                    type="radio"
                                                    name="travelReason"
                                                    id="travelReason-leisure"
                                                    value={"leisure"}
                                                    checked={this.state.cardNum === 1}
                                                    onChange={() => {
                                                        this.setState({cardNum: 1})
                                                    }}/>
                                                <span className="__checkmark"/>
                                            </label>
                                            <label htmlFor="travelReason-business"
                                                   className="custom-radio-container">
                                                <Translator className="__label" content="checkin.radioButton.two"/>
                                                <input
                                                    type="radio"
                                                    name="travelReason"
                                                    id="travelReason-business"
                                                    value={"business"}
                                                    checked={this.state.cardNum === 2}
                                                    onChange={() => {
                                                        this.setState({cardNum: 2})
                                                    }}/>
                                                <span className="__checkmark"/>
                                            </label>
                                        </div>)}

                                        <button
                                            onClick={this.submitRequestKeyCard}
                                            className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space"
                                        >

                                            {this.state.cardNum === 1 ? (
                                                <Translator content={"checkin.buttons.getcard"}/>) : (
                                                 <Translator content={"checkin.buttons.getcards"}/>)}
                                            <i className="icon icon-stayery-arrow-r"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default RoomInformation;
