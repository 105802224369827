import React, {Component} from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

let animationData = require('./Loading-Spinner.json');

class LoadingSpinner extends Component {
    contentOfSpinner(){
        return (this.props.isSpinnerVisible) ? (
            <div className="page-loadingspinner">
                    <div className="content-wrapper">
                        <div className="absolute-wrapper">
                            <Player
                                autoplay
                                loop
                                src={animationData}
                            />
                        </div>
                    </div>
            </div>
        ) : false
    }

    render() {
        return this.contentOfSpinner();
    }
}

export default LoadingSpinner;
