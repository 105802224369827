import type {FC}  from 'react';
import Translator from "../Translator";
import React      from "react";

type DateWidths = {
    day: number, month: number, year: number,
}

export type DatePickerHeaderProps = {
    widths: DateWidths
};

export const DatePickerHeader: FC<DatePickerHeaderProps> = (props: DatePickerHeaderProps) => {
    const {
              widths
          } = props;

    return (<div className={'datepicker-header'}>
        <div style={{width: `${widths.day}px`}}>
            <Translator content={'date.day'} />
        </div>
        <div style={{width: `${widths.month}px`}}>
            <Translator content={'date.month'} />
        </div>
        <div style={{width: `${widths.year}px`}}>
            <Translator content={'date.year'} />
        </div>
    </div>);
};
