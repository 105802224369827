import React              from 'react';
import ReactDOM           from 'react-dom';
import App                from './App';
import * as serviceWorker from './serviceWorker';
import packageJson        from '../package.json';

import langEn              from './languages/en';
import langDe              from './languages/de';
import type {ClientConfig} from "@stayery/check-in-terminal-js-client";
import Client              from "@stayery/check-in-terminal-js-client";

import * as Sentry            from '@sentry/browser';
import {createBrowserHistory} from "history";
import {Router}               from "react-router";

import i18n               from "i18next";
import {initReactI18next} from "react-i18next";
import {v4 as uuidv4}     from 'uuid';

Sentry.init({dsn: "https://ce538d75b9b34fb9a73c31149855412f@sentry.io/1356968"});
Sentry.setTag("version", packageJson.version);

const STORAGE_ID = "CHECKIN_TERMINAL_STORAGE_ID";
let uuid         = localStorage.getItem(STORAGE_ID);
if (uuid === null) {
    uuid = uuidv4();
    localStorage.setItem(STORAGE_ID, uuid)
}

Sentry.setTag("uuid", uuid);

export const DEFAULT_LANG = 'de';

let counterpart = require('counterpart');
counterpart.registerTranslations('en', langEn);
counterpart.registerTranslations('de', langDe);
counterpart.setLocale(DEFAULT_LANG);
let options = {
    lng:       DEFAULT_LANG,
    languages: ['en', 'de'],
    resources: {
        en:            {
            translation: langEn
        },
        de:            {
            translation: langDe
        },
        interpolation: {
            escapeValue: false
        },
        fallbackLng:   DEFAULT_LANG
    }
};
i18n.use(initReactI18next).init(options);

let rootElement          = document.getElementById('root');
let config: ClientConfig = {
    baseUrl: rootElement.dataset.api,
    version: packageJson.version
};

let apiClient     = new Client(config);
apiClient.onError = function(error) {
    Sentry.addBreadcrumb({message: 'ApiClientError'})
    Sentry.captureException(error);
};
window.setInterval(() => {
    apiClient.ping(uuid);
}, 5 * 60 * 1000);


export const theHistory = createBrowserHistory();

ReactDOM.render(<Router history={theHistory}>
    <App apiClient={apiClient}/>
</Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
