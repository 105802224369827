// @flow
import React, {Component} from 'react';
import Header             from "../Header/Header";
import LoadingSpinner     from "../LoadingSpinner/LoadingSpinner";
import ErrorHandler       from "../ErrorHandler/ErrorHandler";
import {Redirect}         from "react-router";
import * as Sentry        from "@sentry/browser";
import {Severity}         from "@sentry/types/dist/severity";
import Translator         from "../../components/Translator";

class ConsultFrontOffice extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            errorMessage:     '',
            showErrorMessage: false,
            isSpinnerVisible: false
        };

        this.handleOK = this.handleOK.bind(this);
    }

    showErrorMessage(translationId)
    {
        this.setState({
                          errorMessage:     translationId,
                          showErrorMessage: true
                      });
        setTimeout(() => {
            this.setState({showErrorMessage: false})
        }, 8000);
    }

    handleOK(event)
    {
        this.setState({redirect: true})
    }

    componentDidMount = () => {
        Sentry.captureEvent({
                                level:   Severity.Info,
                                message: 'Reservation ' + (this.props.reservation?.id ?? '-') +
                                             ' Checkin completed: consult front office'
                            });
    }

    render()
    {
        if (this.state.redirect) {
            return (<Redirect refresh={true} to="/main/thankyou"/>);
        }


        let hotlineNumber = <Translator content={"help.number"}/>;

        const localStorageHotline = localStorage.getItem('hotline')
        if (localStorageHotline && localStorageHotline !== '') {
            hotlineNumber = <span>{localStorageHotline}</span>
        }

        return (<div className="page-consultfrontoffice">
            {(this.state.showErrorMessage) ? <ErrorHandler errorMessage={this.state.errorMessage}/> : false}
            <LoadingSpinner
                isSpinnerVisible={this.state.isSpinnerVisible}
            />
            <div className="page-inner-wrapper">
                <Header
                    headerTitle="pageTitles.consultfrontoffice"
                />
                <div className="content-wrapper">
                    <div className="container">
                        <div className="room-information">
                            <div className="row">
                                <div className="col-6">
                                    <div className="room-number">
                                        <div className="room-number-inner">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="room-information">
                                        <h2 className="h2-heading">
                                            <Translator content={"consultfrontoffice.text.headline"}/>
                                        </h2>
                                        <div className="description">
                                            <Translator content={"consultfrontoffice.text.text"}/>
                                        </div>
                                        <h2 className="h2-heading">
                                            {hotlineNumber}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default ConsultFrontOffice;
