// @flow
import React, {Component} from 'react';
import Translator         from "../../components/Translator";
import type {SignedGuest} from "@stayery/check-in-terminal-js-client";
import Header             from "../Header/Header";
import {Redirect}         from "react-router";
import ErrorHandler       from "../ErrorHandler/ErrorHandler";
import SignaturePad       from 'react-signature-pad-wrapper'
import LoadingSpinner     from "../LoadingSpinner/LoadingSpinner";
import * as Sentry        from '@sentry/browser';
import translator         from "counterpart";

class Signature extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            errors:           {},
            errorMessage:     '',
            showErrorMessage: false,
            isSpinnerVisible: false
        };

        this.handleSubmit   = this.handleSubmit.bind(this);
        this.signatureClear = this.signatureClear.bind(this);
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();
        if (this.formIsValid()) {
            this.setState({isSpinnerVisible: true});

            let formData: SignedGuest = this.props.location.state.formData;
            formData.signature        = this.signaturePad.toDataURL("image/svg+xml");

            Sentry.addBreadcrumb({message: 'Reservation ' + (this.props.reservation?.id ?? '-') + ' signature => citytax'});
            this.props.history.push({
                                        pathname: 'citytax'
                                    }, {
                                        formData: formData
                                    });
        }
        return false;
    }

    signatureClear(event)
    {
        event.preventDefault();
        if (this.signaturePad) {
            this.signaturePad.clear();
        }
    }

    formIsValid()
    {
        let isValid = true;
        let errors  = {};

        if (this.signaturePad.isEmpty()) {
            isValid = false;
            this.showErrorMessage("messages.errors.required");
            errors['signature'] = translator.translate("messages.errors.required");
        }

        this.setState({errors: errors});
        return isValid;
    }

    showErrorMessage(translationId)
    {
        this.setState({
                          errorMessage:     translationId,
                          showErrorMessage: true
                      });
        setTimeout(() => {
            this.setState({showErrorMessage: false})
        }, 8000);
    }

    render()
    {
        if (!this.props.reservation) {
            return <Redirect to={'/'} refresh={true}/>;
        }

        return (<div className="page-signature">
            <LoadingSpinner isSpinnerVisible={this.state.isSpinnerVisible}/>
            <div className="page-inner-wrapper">
                {(this.state.showErrorMessage) ? <ErrorHandler errorMessage={this.state.errorMessage}/> : false}

                <Header
                    backButtonVisible={true}
                    headerTitle="pageTitles.signaturePage"
                />
                <div className="content-wrapper">
                    <div className="container">
                        <div className="content-inner-wrapper">
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                <div className="row">
                                    <div className="col-12">
                                        <Translator content="signature.text"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="control-label">
                                                <Translator content={"fields.signature"}/>
                                            </label>
                                            <div
                                                className={"signature-pad _big " +
                                                    (this.state.errors.hasOwnProperty('signature') ? 'is-invalid'
                                                                                                   : '')}>
                                                <SignaturePad ref={ref => this.signaturePad = ref}/>
                                                <button style={{"height": "100%"}} onClick={this.signatureClear}>
                                                    X
                                                </button>
                                            </div>
                                        </div>
                                        <div className="bodytext-app text-tablet-xs">
                                            <Translator content="signature.information"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 offset-6">
                                        <button className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
                                            <Translator content="signature.button"/>
                                            <i className="icon icon-stayery-arrow-r"/>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default Signature;
