// @flow
import React, {Component} from 'react';
import type {SignedGuest} from "@stayery/check-in-terminal-js-client";
import Header             from "../Header/Header";
import {Redirect}         from "react-router";
import ErrorHandler       from "../ErrorHandler/ErrorHandler";
import LoadingSpinner     from "../LoadingSpinner/LoadingSpinner";
import * as Sentry        from '@sentry/browser';
import CountrySelect      from "../../components/CountrySelect";
import Translator         from "../../components/Translator";
import counterpart        from "counterpart";
import {DatePicker}       from "../../components/datepicker/DatePicker";

class Address extends Component
{

    formAddressRequired = {
        lastName:   true,
        firstName:  true,
        birthDate:  true,
        address:    true,
        postalCode: true,
        city:       true,
        country:    true,
        email:      true,
        phone:     true
    };

    constructor(props)
    {
        super(props);

        this.state = {
            errors:           {},
            errorMessage:     '',
            showErrorMessage: false,
            isSpinnerVisible: false,
            previousPhone: '',
            signedGuest:      {
                lastName:   '',
                firstName:  '',
                birthDate:  '',
                email:      '',
                address:    '',
                postalCode: '',
                city:       '',
                country:    '',
                phone:     ''
            }
        };

        this.formInputTextWithLabel = this.formInputTextWithLabel.bind(this);
        this.handleChange           = this.handleChange.bind(this);
        this.handleSubmit           = this.handleSubmit.bind(this);
        this.signatureClear         = this.signatureClear.bind(this);
        this.componentDidMount      = this.componentDidMount.bind(this);
    }

    componentDidMount()
    {
        if (!this.props.reservation) {
            return <Redirect to={'/'} refresh={true}/>;
        }
        const {reservation} = this.props;
        let address         = this.getAddress(reservation.primaryGuest);

        let email = reservation.primaryGuest.email || '';
        if (reservation.externalCode && !(reservation.channelCode === 'Ibe' || reservation.channelCode === 'Direct')) {
            email = '';
        }
        let phone = reservation.primaryGuest.phone || '+49';
        // phone = phone.replace(/[^0-9+]/g,"");
        this.setState({
                          previousPhone: phone,
                          signedGuest: {
                              ...this.state.signedGuest,
                              lastName:   reservation.primaryGuest.lastName || '',
                              firstName:  reservation.primaryGuest.firstName || '',
                              birthDate:  reservation.primaryGuest.birthDate || '',
                              phone:      phone,
                              email:      email,
                              address:    address.address || '',
                              postalCode: address.postalCode || '',
                              city:       address.city || '',
                              country:    address.country || 'DE'
                          }
                      });
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();
        let signedGuest: SignedGuest = {
            lastName:   this.state.signedGuest.lastName,
            firstName:  this.state.signedGuest.firstName,
            birthDate:  this.state.signedGuest.birthDate,
            address:    this.state.signedGuest.address,
            email:      this.state.signedGuest.email,
            postalCode: this.state.signedGuest.postalCode,
            city:       this.state.signedGuest.city,
            country:    this.state.signedGuest.country,
            phone:    this.state.signedGuest.phone
        };
        if (this.formIsValid(signedGuest)) {

            let primaryGuest = this.props.reservation.primaryGuest;

            primaryGuest.lastName  = signedGuest.lastName;
            primaryGuest.firstName = signedGuest.firstName;
            primaryGuest.birthDate = signedGuest.birthDate;
            primaryGuest.email     = signedGuest.email;
            primaryGuest.phone     = signedGuest.phone;

            if (!primaryGuest.address) {
                primaryGuest.address = {};
            }

            primaryGuest.address.addressLine1 = signedGuest.address;
            primaryGuest.address.postalCode   = signedGuest.postalCode;
            primaryGuest.address.city         = signedGuest.city;
            primaryGuest.address.countryCode  = signedGuest.country;
            primaryGuest.preferredLanguage    = counterpart.getLocale().toUpperCase();


            this.setState({isSpinnerVisible: true});
            Sentry.addBreadcrumb({message: 'update primary guest for ' + (this.props.reservation?.id ?? '-')});
            this.props.apiClient.updatePrimaryGuest(this.props.reservation.id,
                                                    this.props.reservation.booker.lastName,
                                                    primaryGuest
            )
                .then((response) => {
                    const message = 'primary guest updated for ' + (this.props.reservation?.id ?? '-');
                    if (typeof response === 'undefined' || response === null) {
                        // currently no handling, because stayery wants it so: Asana "Gästedaten wurden nicht an Apaleo übermittelt"
                        Sentry.captureEvent({
                                                message: 'update primary guest was for ' +
                                                             (this.props.reservation?.id ?? '-') + ' null or undefined'
                                            });
                    }
                    if (this.props.reservation._meta.hasCoronaNotice) {
                        Sentry.addBreadcrumb({message: message + ' address => corona'});
                        this.props.history.push({
                                                    pathname: 'corona'
                                                }, {
                                                    formData: signedGuest
                                                });
                    } else {
                        Sentry.addBreadcrumb({message: message + ' address => signature'});
                        this.props.history.push({
                                                    pathname: 'signature'
                                                }, {
                                                    formData: signedGuest
                                                });
                    }
                })
                .catch((err) => {
                    Sentry.addBreadcrumb({message: 'update of primary guest failed'});
                    Sentry.captureException(err);
                    this.setState({isSpinnerVisible: false});
                });

        }
        return false;
    }

    handleChange(event)
    {
        const errors = this.state.errors;
        if (errors.hasOwnProperty(event.target.name)) {
            delete errors[event.target.name];
        }
        if(event.target.name === "phone") {
                if (event.target.checkValidity() || event.target.value === "") {
                    this.setState({
                        previousPhone: event.target.value,
                        errors:      errors,
                        signedGuest: {
                            ...this.state.signedGuest,
                            [event.target.name]: event.target.value
                        }
                    });
                } else {
                    this.setState({
                        errors:      errors,
                        signedGuest: {
                            ...this.state.signedGuest,
                            [event.target.name]: this.state.previousPhone
                        }
                    });
                }
        } else {
            this.setState({
                errors:      errors,
                signedGuest: {
                    ...this.state.signedGuest,
                    [event.target.name]: event.target.value
                }
            });
        }

    }

    signatureClear(event)
    {
        event.preventDefault();
        if (this.signaturePad) {
            this.signaturePad.clear();
        }
    }

    formInputTextWithLabel(label: string,
        name: string,
        value: string,
        type: string,
        readOnly: boolean,
        maxlength: number | null,
        pattern: string = ""
    )
    {
        let required          = this.isFieldRequired(name);
        let inputType         = type || 'text';
        let requiredClass     = "control-label " + (required ? 'required' : 'optional');
        let className         = "form-control";
        let classNameReadOnly = readOnly ? 'readonly disabled' : '';
        let errorMessage      = null;

        if (this.state.errors.hasOwnProperty(name)) {
            className += ' is-invalid';
        }

        if (typeof maxlength === 'undefined') {
            maxlength = null;
        }

        return (<div className="form-group">
            <label className={requiredClass}>
                <Translator content={label}/>
            </label>
            <div className={classNameReadOnly}>
                <input type={inputType}
                       className={className}
                       value={value}
                       name={name}
                       title={errorMessage}
                       required={required}
                       readOnly={readOnly}
                       disabled={readOnly}
                       onChange={this.handleChange}
                       autoComplete="off"
                       placeholder=" "
                       maxLength={maxlength}
                       pattern={pattern}
                />
                <i className="icon icon-stayery-group hidden"/>
            </div>
        </div>);
    }

    formIsValid(signedGuest: SignedGuest)
    {
        let errors = {};
        for (let field in this.formAddressRequired) {
            if (this.formAddressRequired.hasOwnProperty(field)) {
                let required = this.formAddressRequired[field];
                if (required && !(signedGuest.hasOwnProperty(field) && signedGuest[field])) {
                    errors[field] = "messages.errors.required";
                }
            }
        }

        if (signedGuest.hasOwnProperty('phone')) {
            const phone = signedGuest['phone'].trim();
            const regex = new RegExp(/^\+[0-9]{0,2}$/);
            if (regex.test(phone) || !phone) {
                errors.phone = "messages.errors.required";
            } else {
                delete errors.phone;
            }
        }

        if (signedGuest.hasOwnProperty('email')) {
            const email = signedGuest['email'];
            const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
            if (!regex.test(email)) {
                errors.email = "messages.errors.email";
            } else {
                delete errors.email;
            }
        }

        if (Object.keys(errors).length) {
            this.showErrorMessage(errors[Object.keys(errors)[0]]);
        }

        this.setState({errors: errors});
        return !Object.keys(errors).length;
    }

    isFieldRequired(field)
    {
        let required = false;
        if (this.formAddressRequired.hasOwnProperty(field)) {
            required = this.formAddressRequired[field];
        }

        return required;
    }

    showErrorMessage(translationId)
    {
        this.setState({
                          errorMessage:     translationId,
                          showErrorMessage: true
                      });
        setTimeout(() => {
            this.setState({showErrorMessage: false})
        }, 8000);
    }

    componentWillUnmount()
    {
        const el = document.getElementById('buttons.confirmInformations');
        if (el) {
            el.remove();
        }//
    }

    render()
    {
        if (!this.props.reservation) {
            return <Redirect to={'/'} refresh={true}/>;
        }

        return (<div className="page-address">
            <LoadingSpinner isSpinnerVisible={this.state.isSpinnerVisible}/>
            <div className="page-inner-wrapper">
                {(this.state.showErrorMessage) ? <ErrorHandler errorMessage={this.state.errorMessage}/> : false}

                <Header
                    backButtonVisible={true}
                    headerTitle="pageTitles.addressPage"
                />
                <div className="content-wrapper">
                    <div className="container">
                        <div className="content-inner-wrapper">
                            <form onSubmit={this.handleSubmit} noValidate={true} autoComplete="off">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            {this.formInputTextWithLabel("fields.lastName",
                                                                         'lastName',
                                                                         this.state.signedGuest.lastName,
                                                                         'text',
                                                                         false
                                            )}
                                        </div>
                                        <div className="form-group">
                                            {this.formInputTextWithLabel("fields.firstName",
                                                                         'firstName',
                                                                         this.state.signedGuest.firstName,
                                                                         'text',
                                                                         false
                                            )}
                                        </div>
                                        <DatePicker
                                            label={'fields.birthDate'}
                                            value={this.state.signedGuest.birthDate}
                                            name="birthDate"
                                            onChange={this.handleChange}
                                            error={this.state.errors.hasOwnProperty("birthDate")}
                                        />
                                        <div className="form-group">
                                            {this.formInputTextWithLabel("fields.email",
                                                                         'email',
                                                                         this.state.signedGuest.email,
                                                                         'email',
                                                                         false
                                            )}
                                        </div>
                                        <div className="form-group">
                                            {this.formInputTextWithLabel("fields.phone",
                                                'phone',
                                                this.state.signedGuest.phone,
                                                'tel',
                                                false,
                                                null,
                                                "^(\\+[0-9]{0,2})?.*$"
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            {this.formInputTextWithLabel("fields.address",
                                                                         'address',
                                                                         this.state.signedGuest.address,
                                                                         'text',
                                                                         false
                                            )}
                                        </div>
                                        <div className="form-group">
                                            {this.formInputTextWithLabel("fields.postalCode",
                                                                         'postalCode',
                                                                         this.state.signedGuest.postalCode,
                                                                         'text',
                                                                         false,
                                                                         20
                                            )}
                                        </div>
                                        <div className="form-group">
                                            {this.formInputTextWithLabel("fields.city",
                                                                         'city',
                                                                         this.state.signedGuest.city,
                                                                         'text',
                                                                         false
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <CountrySelect
                                                label="fields.country"
                                                required={true}
                                                name="country"
                                                value={this.state.signedGuest.country}
                                                onChange={this.handleChange}
                                                className={this.state.errors.hasOwnProperty("country") ? "is-invalid"
                                                                                                       : ""}
                                            />
                                        </div>
                                        {(() => {
                                            if (this.props.location.pathname !== '/main/checkin/address') {
                                                Sentry.captureMessage("render button confirmInformations on: " +
                                                                          this.props.location.pathname);
                                            }
                                            return <button
                                                id={'buttons.confirmInformations'}
                                                data-role={"buttons.confirmInformations"}
                                                className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
                                                <Translator content="buttons.confirmInformations"/>
                                                <i className="icon icon-stayery-arrow-r"/>
                                            </button>;
                                        })()}
                                        <div className="bodytext-app">
                                            &nbsp;<br/>
                                            <Translator content="address.information"/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    getAddress(booker)
    {
        let address = {
            address:    "",
            postalCode: "",
            city:       "",
            country:    ""
        };

        if (booker.address) {
            address.address    = booker.address.addressLine1;
            address.postalCode = booker.address.postalCode;
            address.city       = booker.address.city;
            address.country    = booker.address.countryCode;
        }

        return address;
    }
}

export default Address;
